import { AuthConsumer } from "./auth/firebase/AuthConsumer";
import { AuthProvider } from "./auth/firebase/AuthProvider";
import MotionLazy from "./components/MotionLazy";
import SnackbarProvider from "./components/SnackbarProvider";
import { useScrollToTop } from "./hooks/useScrollToTop";
import Router from "./routes/Router";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ja } from "date-fns/locale";
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    iframeXS: true;
    iframeMD: true;
    laptop: true;
    desktop: true;
  }
}

const theme: Theme = createTheme({
  breakpoints: {
    values: {
      ...createTheme().breakpoints.values,
      iframeXS: 330,
      iframeMD: 450,
    },
  },
});

function App() {
  useScrollToTop();
  return (
    <div className="App">
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <MotionLazy>
            <SnackbarProvider>
              <AuthConsumer>
                <Router />
              </AuthConsumer>
            </SnackbarProvider>
          </MotionLazy>
        </LocalizationProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
