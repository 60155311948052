import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {
  FormCondition,
  FormFields,
  IForm,
  IPreviewVideoChooseForm,
  Rule,
} from "src/types/video";
import {
  Box,
  Button,
  CircularProgress,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import FieldItem from "./FieldItem";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import axios from "axios";

type Props = {
  videoChooseId: string;
  formId: string;
  fields: FormFields;
  form: IPreviewVideoChooseForm;
  videoRef: React.RefObject<HTMLVideoElement>;
  handleFormModalClose: () => void;
  handleVideoTypeNextAction: (nextVideoId: string) => void;
  handleFormStep?: (formIndex: number, formId: string) => void;
  handleFormStepProgressBar?: (formIndex: number, formId: string) => void;
  setSavedValues: any;
  savedValues: any;
  isIFrame: boolean;
  formsOrdered?: any[];
  selectedFormStep?: number;
  filledFormStep?: number;
  confirmation?: boolean;
  handleChangeConfirmation?: () => void;
};

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

function evaluateCondition(
  fieldValue: any,
  operator: string,
  value: any,
  extraValue?: any
): boolean {
  switch (operator) {
    // 数値関連
    case "greater_than":
      return Number(fieldValue) > Number(value);
    case "greater_than_or_equal_to":
      return Number(fieldValue) >= Number(value);
    case "less_than":
      return Number(fieldValue) < Number(value);
    case "less_than_or_equal_to":
      return Number(fieldValue) <= Number(value);
    case "equal_to":
      return fieldValue === value;
    case "not_equal_to":
      return fieldValue !== value;
    case "between":
      return (
        Number(fieldValue) >= Number(value) &&
        Number(fieldValue) <= Number(extraValue)
      );
    case "outside_range":
      return (
        Number(fieldValue) < Number(value) ||
        Number(fieldValue) > Number(extraValue)
      );

    // 文字列関連
    case "exact_match":
      return fieldValue === value;
    case "does_not_match":
      return fieldValue !== value;
    case "contains":
      return fieldValue.includes(value);
    case "does_not_contain":
      return !fieldValue.includes(value);
    case "starts_with":
      return fieldValue.startsWith(value);
    case "ends_with":
      return fieldValue.endsWith(value);
    case "string_length_greater_than":
      return fieldValue.length > Number(value);
    case "string_length_less_than":
      return fieldValue.length < Number(value);
    case "regex_match":
      return new RegExp(value).test(fieldValue);

    // 日付・時間関連
    case "before":
      return new Date(fieldValue) < new Date(value);
    case "after":
      return new Date(fieldValue) > new Date(value);
    case "date_equal":
      return new Date(fieldValue).toISOString().split("T")[0] === value;
    case "date_within_range":
      return (
        new Date(fieldValue) >= new Date(value) &&
        new Date(fieldValue) <= new Date(extraValue)
      );
    case "date_outside_range":
      return (
        new Date(fieldValue) < new Date(value) ||
        new Date(fieldValue) > new Date(extraValue)
      );

    // 存在チェック
    case "is_present":
      return (
        fieldValue !== undefined && fieldValue !== null && fieldValue !== ""
      );
    case "is_not_present":
      return (
        fieldValue === undefined || fieldValue === null || fieldValue === ""
      );

    // 論理値
    case "is_true":
      return fieldValue === true;
    case "is_false":
      return fieldValue === false;

    // 配列関連
    case "is_in_list":
      return Array.isArray(value) && value.includes(fieldValue);
    case "is_not_in_list":
      return Array.isArray(value) && !value.includes(fieldValue);
    case "list_length_greater_than":
      return Array.isArray(fieldValue) && fieldValue.length > Number(value);
    case "list_length_less_than":
      return Array.isArray(fieldValue) && fieldValue.length < Number(value);

    default:
      return false;
  }
}

function evaluateConditions(
  rules: Rule[],
  fieldValues: { [key: string]: any }
): boolean[] {
  return rules.map((rule) => {
    const { fieldId, operator, value, extraValue } = rule;
    const fieldValue = fieldValues[fieldId];
    return evaluateCondition(fieldValue, operator, value, extraValue);
  });
}

function determineNextVideoId(
  conditions: FormCondition[],
  fieldValues: { [key: string]: any }
): string | null {
  for (const condition of conditions) {
    const { rules, logic, videoId } = condition;

    // 各ルールを評価
    const ruleResults = evaluateConditions(rules, fieldValues);

    // 論理条件に基づいて全体結果を統合
    const conditionMet =
      logic === "AND"
        ? ruleResults.every((result) => result) // AND条件
        : ruleResults.some((result) => result); // OR条件

    if (conditionMet) {
      return videoId; // 条件を満たした videoId を返す
    }
  }

  return null; // どの条件も満たされない場合
}

export default function VideoChooseForm({
  videoChooseId,
  formId,
  form,
  fields,
  videoRef,
  handleFormModalClose,
  handleVideoTypeNextAction,
  handleFormStep,
  handleFormStepProgressBar,
  setSavedValues,
  savedValues,
  isIFrame,
  formsOrdered,
  selectedFormStep,
  filledFormStep,
  confirmation,
  handleChangeConfirmation,
}: Props) {
  const [fieldValues, setFieldValues] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [emptyErrorFieldIds, setEmptyErrorFieldIds] = useState<string[]>([]);
  const [steps, setSteps] = useState<string[]>([]);
  const [getSessionStorageStep, setGetSessionStorageStep] = useState<number>(0);

  useEffect(() => {
    if (!formsOrdered) return;
    let formTitles = formsOrdered.map((form) => form.title);
    setSteps(formTitles);
  }, [form, formsOrdered]);

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>(
    {}
  );

  function sendMessageAndWaitForSessionStorage(message: any): Promise<any> {
    return new Promise((resolve, reject) => {
      function handleMessage(event: MessageEvent) {
        if (event.data && event.data.action === "getSessionStorage") {
          window.removeEventListener("message", handleMessage);
          resolve(event.data);
        }
      }
      window.addEventListener("message", handleMessage);

      window.parent.postMessage(message, "*");
    });
  }

  useEffect(() => {
    if (selectedFormStep && selectedFormStep <= getSessionStorageStep) {
      return;
    }
    const fetchSessionStorage = async () => {
      try {
        const response = await sendMessageAndWaitForSessionStorage({
          action: "getSessionStorage",
        });

        const data = response;

        if (data.action === "getSessionStorage") {
          const sessionValues = data.sessionValues;

          sessionValues.forEach(({ key, value }: { key: any; value: any }) => {
            const field = Object.values(fields).find(
              (f) => f.fieldCustomId === key
            );

            if (!!field) {
              let processedValue = value;
              if (key === "birthday") {
                processedValue = value;
              }
              if (key === "credit_card") {
                processedValue = {
                  ...value,
                  expiryDate: new Date(value.expiryDate),
                };
              }

              setFieldValues((prevValues: any) => ({
                ...prevValues,
                [field.id]: processedValue,
              }));

              if (key === "postal_code" && value) {
                window.parent.postMessage(
                  {
                    action: "formSync",
                    value: value,
                    field: key,
                  },
                  "*"
                );
              }
            }
          });
        }
        if (
          selectedFormStep !== undefined &&
          selectedFormStep > getSessionStorageStep
        ) {
          setGetSessionStorageStep(selectedFormStep);
        }
      } catch (error) {
        console.error("Error fetching session storage:", error);
      }
    };

    fetchSessionStorage();
  }, [fields]);

  const [errorMessages, setErrorMessages] = useState<{
    [key: string]: string[];
  }>({});

  const handleChangeFieldErrors = (fieldName: string, hasError: boolean) => {
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: hasError,
    }));
  };

  const handleChangeErrorMessages = (fieldName: string, messages: string[]) => {
    setErrorMessages((prev) => ({
      ...prev,
      [fieldName]: messages,
    }));
  };

  const [isCompleteSubmission, setIsCompleteSubmission] =
    useState<boolean>(false);

  const [orderDetails, setOrderDetails] = useState<any>({});

  useEffect(() => {
    const initialValues = Object.keys(fields).reduce(
      (acc: any, fieldId: string) => {
        acc[fieldId] = fields[fieldId].defaultValue || "";
        return acc;
      },
      {}
    );
    if (savedValues && savedValues[formId]) {
      Object.keys(savedValues[formId]).forEach((key) => {
        initialValues[key] = savedValues[formId][key];
      });
    }
    setFieldValues(initialValues);
  }, [fields]);

  const handleInputChange = (fieldId: string, value: any) => {
    setFieldValues((prevValues: any) => ({
      ...prevValues,
      [fieldId]: value,
    }));

    if (fields[fieldId].fieldName === "postal_code" && value) {
      const fetchAddress = async () => {
        try {
          const response = await axios.get(
            `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${value}`
          );
          if (
            response.data.status === 200 &&
            response.data.results &&
            response.data.results.length > 0
          ) {
            // const prefectureFieldId = Object.keys(fields).filter(
            //   (key) => fields[key].fieldName === "prefecture"
            // )[0];
            // const cityFieldId = Object.keys(fields).filter(
            //   (key) => fields[key].fieldName === "city"
            // )[0];

            const prefectureFieldId = Object.keys(fields).find(
              (key) => fields[key].fieldName === "prefecture"
            );
            const cityFieldId = Object.keys(fields).find(
              (key) => fields[key].fieldName === "city"
            );

            // setFieldValues((prevValues: any) => ({
            //   ...prevValues,
            //   [prefectureFieldId]: response.data.results[0].address1,
            //   [cityFieldId]:
            //     response.data.results[0].address2 +
            //     response.data.results[0].address3,
            // }));
            if (prefectureFieldId && cityFieldId) {
              setFieldValues((prevValues: any) => ({
                ...prevValues,
                [prefectureFieldId]: response.data.results[0].address1 || "",
                [cityFieldId]:
                  (response.data.results[0].address2 || "") +
                  (response.data.results[0].address3 || ""),
              }));
            } else {
              console.warn("Prefecture or city field not found");
            }
          } else {
            console.warn("No results found in the response");
          }
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      };
      fetchAddress();
    }

    if (form.send_type === "custom_script") {
      window.parent.postMessage(
        {
          action: "formSync",
          value: value,
          field: fields[fieldId].fieldCustomId,
        },
        "*"
      );
    }
    if (form.send_type === "submit" && form.is_form_sync_with_submit) {
      window.parent.postMessage(
        {
          action: "formSync",
          value: value,
          field: fields[fieldId].fieldCustomId,
        },
        "*"
      );
    }
  };

  function sendMessageAndWaitForResponse(message: any): Promise<any> {
    return new Promise((resolve, reject) => {
      function handleMessage(event: MessageEvent) {
        if (event.data && event.data.action === "formCustomScriptResponse") {
          window.removeEventListener("message", handleMessage);
          resolve(event.data);
        }
      }
      window.addEventListener("message", handleMessage);

      window.parent.postMessage(message, "*");
    });
  }

  const handleSubmit = async () => {
    setLoading(true);

    const validateFields = () => {
      const requiredFields = Object.keys(fields).filter(
        (fieldId) => fields[fieldId].required
      );

      const emptyFields = requiredFields.filter(
        (fieldId) =>
          !fieldValues[fieldId] && fields[fieldId].type !== "credit_card"
      );

      // const separatedFields = Object.keys(fields).filter(
      //   (fieldId) =>
      //     (fields[fieldId].type === "names_separated" ||
      //       fields[fieldId].type === "names_separated_furigana" ||
      //       fields[fieldId].type === "credit_card") &&
      //     fields[fieldId].required
      // );

      const separatedFields = Object.keys(fields).filter(
        (fieldId) =>
          (fields[fieldId].type === "names_separated" ||
            fields[fieldId].type === "names_separated_furigana" ||
            (fields[fieldId].conditionFieldValues !== undefined &&
              fields[fieldId].type === "credit_card" &&
              fields[fieldId].conditionFieldValues?.includes(
                fieldValues[fields[fieldId].conditionField as string]
              ))) &&
          fields[fieldId].required
      );

      const emptySeparatedFields = separatedFields.filter((fieldId) => {
        const value = fieldValues[fieldId];
        return !value || Object.keys(value).some((key) => !value[key]);
      });

      const checkboxFields = Object.keys(fields).filter(
        (fieldId) =>
          fields[fieldId].type === "checkbox" && fields[fieldId].required
      );

      const uncheckedCheckboxFields = checkboxFields.filter(
        (fieldId) => !fieldValues[fieldId]
      );

      const combinedEmptyFields = [
        ...emptyFields,
        ...emptySeparatedFields,
        ...uncheckedCheckboxFields,
      ];

      const emailFieldsWithError = Object.keys(fields).filter((fieldId) => {
        const field = fields[fieldId];
        if (field.type === "email" && !validateEmail(fieldValues[fieldId])) {
          return true;
        }
        return false;
      });

      const allValuesAreEmpty = Object.values(fieldValues).every(
        (value) => value === ""
      );

      const fieldErrorsExist = Object.values(fieldErrors).some(
        (value) => value === true
      );

      return {
        combinedEmptyFields,
        emailFieldsWithError,
        allValuesAreEmpty,
        fieldErrorsExist,
      };
    };

    const {
      combinedEmptyFields,
      emailFieldsWithError,
      allValuesAreEmpty,
      fieldErrorsExist,
    } = validateFields();

    if (combinedEmptyFields.length > 0) {
      enqueueSnackbar("必須項目を入力してください", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setEmptyErrorFieldIds(combinedEmptyFields);
      setLoading(false);
      return;
    }

    if (emailFieldsWithError.length > 0) {
      enqueueSnackbar("Email 形式が無効です", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setLoading(false);
      return;
    }

    if (allValuesAreEmpty) {
      enqueueSnackbar("入力内容を確認してください", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setLoading(false);
      return;
    }

    if (fieldErrorsExist) {
      enqueueSnackbar("フィールドにエラーがあります", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setLoading(false);
      return;
    }

    if (
      formsOrdered &&
      selectedFormStep !== undefined &&
      filledFormStep &&
      formsOrdered?.length > 0 &&
      selectedFormStep < filledFormStep
    ) {
      setSavedValues({
        ...savedValues,
        [formId]: fieldValues,
      });
      if (form.is_waiting_validation) {
        try {
          const response = await sendMessageAndWaitForResponse({
            action: "formSend",
            scriptId: form.custom_script_id,
            value: fieldValues,
            fields: fields,
            response: true,
          });

          if (response.status === "success" && handleFormStep) {
            handleFormStep(selectedFormStep + 1, formId);
          } else {
            console.error("Validation failed:", response.message);

            const keys = Object.keys(fields);
            for (const key of keys) {
              if (fields[key].fieldCustomId === response.errorFieldCustomId) {
                setFieldErrors((prevErrors) => ({
                  ...prevErrors,
                  [key]: true,
                }));
                setErrorMessages((prev) => ({
                  ...prev,
                  [key]: [response.errorMessage],
                }));
              }
            }

            enqueueSnackbar("検証に失敗しました", {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "center" },
            });
          }
        } catch (error) {
          console.error("Error waiting for validation response:", error);
        }
      } else {
        window.parent.postMessage(
          {
            action: "formSend",
            scriptId: form.custom_script_id,
            value: fieldValues,
            fields: fields,
          },
          "*"
        );
        if (handleFormStep) {
          handleFormStep(selectedFormStep + 1, formId);
        }
      }
    } else {
      if (form.send_type === "submit") {
        const db = getFirestore();
        const dataRef = collection(
          db,
          `videoChooses/${videoChooseId}/forms/${formId}/data`
        );
        try {
          const docRef = await addDoc(dataRef, {
            fieldValues,
            savedValues,
            createdAt: new Date(),
          });

          setIsCompleteSubmission(true);

          if (
            form.is_custom_script_after_submission &&
            form.custom_script_id_after_submission &&
            form.custom_script_id_after_submission !== ""
          ) {
            // window.open(form.redirect_url_after_submission);
            window.parent.postMessage(
              {
                action: "CustomScriptAfterSubmission",
                scriptId: form.custom_script_id_after_submission,
              },
              "*"
            );
          }
        } catch (e) {
          console.error("Error adding document: ", e);
          enqueueSnackbar("送信に失敗しました", {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        }
      } else if (form.send_type === "video") {
        setSavedValues({
          ...savedValues,
          [formId]: fieldValues,
        });

        let nextVideoId = form.video_id;

        // form.conditions が存在する場合、条件を評価
        if (form.conditions && form.conditions.length > 0) {
          nextVideoId =
            determineNextVideoId(form.conditions, fieldValues) || nextVideoId;
        }

        if (nextVideoId) {
          handleVideoTypeNextAction(nextVideoId);
        }
        handleFormModalClose();
      } else if (form.send_type === "custom_script") {
        setSavedValues({
          ...savedValues,
          [formId]: fieldValues,
        });
        if (form.is_waiting_validation) {
          try {
            const response = await sendMessageAndWaitForResponse({
              action: "formSend",
              scriptId: form.custom_script_id,
              value: fieldValues,
              fields: fields,
              response: true,
            });

            if (response.status === "success") {
              if (form.is_action_with_custom_script) {
                if (form.action_type === "form") {
                  if (handleFormStep && selectedFormStep !== undefined) {
                    handleFormStep(selectedFormStep + 1, formId);
                  }
                } else if (form.action_type === "video") {
                  const nextVideoId = form.video_id;

                  if (nextVideoId) {
                    handleVideoTypeNextAction(nextVideoId);
                  }
                  handleFormModalClose();
                } else if (form.action_type === "confirmation") {
                  if (
                    handleChangeConfirmation &&
                    response.orderDetails.shippingAddressFullName
                  ) {
                    handleChangeConfirmation();
                    setOrderDetails(response.orderDetails);
                  }
                }
              }
            } else {
              const keys = Object.keys(fields);
              for (const key of keys) {
                if (fields[key].fieldCustomId === response.errorFieldCustomId) {
                  setFieldErrors((prevErrors) => ({
                    ...prevErrors,
                    [key]: true,
                  }));
                  setErrorMessages((prev) => ({
                    ...prev,
                    [key]: [response.errorMessage],
                  }));
                }
              }

              enqueueSnackbar("検証に失敗しました", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "center" },
              });
            }
          } catch (error) {
            console.error("Error waiting for validation response:", error);
          }
        } else {
          window.parent.postMessage(
            {
              action: "formSend",
              scriptId: form.custom_script_id,
              value: fieldValues,
              fields: fields,
            },
            "*"
          );
          if (form.is_action_with_custom_script) {
            if (form.action_type === "form") {
              if (handleFormStep && selectedFormStep !== undefined) {
                handleFormStep(selectedFormStep + 1, formId);
              }
            } else if (form.action_type === "video") {
              const nextVideoId = form.video_id;

              if (nextVideoId) {
                handleVideoTypeNextAction(nextVideoId);
              }
              handleFormModalClose();
            }
          }
        }
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Paper
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.78)",
          overflowY: "auto",
        }}
      >
        <Stack
          style={{
            width: "100%",
            height: "auto",
            paddingTop: 50,
            zIndex: 1000,
          }}
        >
          {confirmation ? (
            <Stack
              spacing={1}
              className="custom-scrollbar"
              sx={{
                py: 1,
                maxWidth: 380,
                width: "100%",
                alignItems: "center",
                webkitOverflowScrolling: "touch",
                overflowY: "auto",
                margin: "0 auto",
              }}
              onTouchMove={(e) => {
                e.stopPropagation();
              }}
            >
              <Stack
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                alignSelf="center"
                sx={{ maxWidth: 380, width: "100%", paddingTop: 2 }}
              >
                <Box
                  sx={{
                    padding: "0 6px",
                    margin: "0 auto",
                    maxWidth: 380,
                    width: "80%",
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#fff",
                      textAlign: "center",
                      fontSize: "0.85rem",
                    }}
                  >
                    ご注文内容確認
                  </Typography>
                </Box>
              </Stack>
              <div style={orderDetailStyles.orderDetails}>
                {/* 商品情報セクション */}
                <div style={orderDetailStyles.section}>
                  <div style={orderDetailStyles.itemList}>
                    {orderDetails.productName && (
                      <p style={orderDetailStyles.item}>
                        <strong>商品名:</strong> {orderDetails.productName}
                      </p>
                    )}

                    {orderDetails.productPrice && (
                      <p style={orderDetailStyles.item}>
                        <strong>単価:</strong> {orderDetails.productPrice}
                      </p>
                    )}

                    {orderDetails.productQuantity && (
                      <p style={orderDetailStyles.item}>
                        <strong>個数:</strong> {orderDetails.productQuantity}
                      </p>
                    )}

                    {orderDetails.productSubtotalPrice && (
                      <p style={orderDetailStyles.item}>
                        <strong>小計:</strong>{" "}
                        {orderDetails.productSubtotalPrice}
                      </p>
                    )}
                  </div>
                </div>

                {/* 金額情報セクション */}
                <div style={orderDetailStyles.section}>
                  <div style={orderDetailStyles.itemList}>
                    {orderDetails.subtotal10 && (
                      <p style={orderDetailStyles.item}>
                        <strong>小計:</strong> {orderDetails.subtotal10}
                      </p>
                    )}
                    {orderDetails.productSubtotalPrice && (
                      <p style={orderDetailStyles.item}>
                        <strong>小計:</strong>{" "}
                        {orderDetails.productSubtotalPrice}
                      </p>
                    )}

                    {orderDetails.subtotal11 && (
                      <p style={orderDetailStyles.item}>
                        <strong>送料:</strong> {orderDetails.subtotal11}
                      </p>
                    )}

                    {orderDetails.productShippingPrice && (
                      <p style={orderDetailStyles.item}>
                        <strong>送料:</strong>{" "}
                        {orderDetails.productShippingPrice}
                      </p>
                    )}

                    {orderDetails.subtotal12 && (
                      <p style={orderDetailStyles.item}>
                        <strong>手数料:</strong> {orderDetails.subtotal12}
                      </p>
                    )}

                    {orderDetails.productShippingMargin && (
                      <p style={orderDetailStyles.item}>
                        <strong>手数料:</strong>{" "}
                        {orderDetails.productShippingMargin}
                      </p>
                    )}
                    {orderDetails.tax10 && (
                      <p style={orderDetailStyles.item}>
                        <strong>消費税:</strong> {orderDetails.tax10}
                      </p>
                    )}
                    {orderDetails.tax && (
                      <p style={orderDetailStyles.item}>
                        <strong>消費税:</strong> {orderDetails.tax}
                      </p>
                    )}

                    {orderDetails.totalColorEc && (
                      <p style={orderDetailStyles.item}>
                        <strong>合計:</strong> {orderDetails.totalColorEc}
                      </p>
                    )}

                    {orderDetails.totalPrice && (
                      <p style={orderDetailStyles.item}>
                        <strong>合計:</strong> {orderDetails.totalPrice}
                      </p>
                    )}
                  </div>
                </div>

                {/* 注意事項HTMLセクション */}
                <div style={orderDetailStyles.section}>
                  <div
                    style={orderDetailStyles.cautionContent}
                    dangerouslySetInnerHTML={{
                      __html: orderDetails.cautionHtml,
                    }}
                  />
                </div>

                {/* お客様情報セクション */}
                <div style={orderDetailStyles.section}>
                  <h2 style={orderDetailStyles.sectionHeader}>お客様情報</h2>
                  <div style={orderDetailStyles.itemList}>
                    {orderDetails.shippingAddressFullName && (
                      <p style={orderDetailStyles.item}>
                        <strong>名前:</strong>{" "}
                        {orderDetails.shippingAddressFullName}
                      </p>
                    )}

                    {orderDetails.shippingAddressFullAddress && (
                      <p style={orderDetailStyles.item}>
                        <strong>住所:</strong>{" "}
                        {orderDetails.shippingAddressFullAddress}
                      </p>
                    )}

                    {orderDetails.shippingAddressPhoneNumber && (
                      <p style={orderDetailStyles.item}>
                        <strong>電話番号:</strong>{" "}
                        {orderDetails.shippingAddressPhoneNumber}
                      </p>
                    )}

                    {orderDetails.shippingTime && (
                      <p style={orderDetailStyles.item}>
                        <strong>お届け時間:</strong> {orderDetails.shippingTime}
                      </p>
                    )}

                    {orderDetails.shippingInterval && (
                      <p style={orderDetailStyles.item}>
                        <strong>お届け日時間隔:</strong>{" "}
                        {orderDetails.shippingInterval}
                      </p>
                    )}

                    {orderDetails.paymentMethod && (
                      <p style={orderDetailStyles.item}>
                        <strong>お支払い方法:</strong>{" "}
                        {orderDetails.paymentMethod}
                      </p>
                    )}

                    {orderDetails.paymentDeadline && (
                      <p style={orderDetailStyles.item}>
                        <strong>お支払い期限:</strong>{" "}
                        {orderDetails.paymentDeadline}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <Box
                sx={{
                  maxWidth: 380,
                  width: "100%",
                  alignItems: "center",
                  alignSelf: "center",
                  bottom: "12px",
                  margin: "0 auto",
                }}
              >
                <Button
                  id="submitButton"
                  sx={{
                    backgroundColor: form.send_color || "#66c5c7",
                    color: "#fff",
                    borderRadius: "100px",
                    padding: "6px 12px",
                    margin: "0 auto",
                    display: "block",
                    width: "87%",
                    height: 50,
                    "&:hover": {
                      backgroundColor: form.send_color || "#66c5c7",
                    },
                  }}
                  onClick={async () => {
                    if (!loading) {
                      setLoading(true);

                      try {
                        const result = await sendMessageAndWaitForResponse({
                          action: "formSend",
                          scriptId: "purchaseComplete",
                        });

                        if (result && result.status === "success") {
                          enqueueSnackbar("購入が完了しました", {
                            variant: "success",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                          });
                        } else {
                          enqueueSnackbar("購入に失敗しました", {
                            variant: "error",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                          });
                        }
                      } catch (error) {
                        console.error("Error processing purchase:", error);
                        enqueueSnackbar("エラーが発生しました", {
                          variant: "error",
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        });
                      } finally {
                        setLoading(false);
                      }
                    }
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Typography variant="body2" sx={{ color: "#fff" }}>
                      購入する
                    </Typography>
                  )}
                </Button>
                <Box sx={commonStyles.footerBox}>
                  <Box
                    style={{
                      fontSize: 13,
                    }}
                  >
                    Powered by &nbsp;
                  </Box>
                  <Button
                    onClick={() => {
                      if (videoRef.current) {
                        videoRef.current.pause();
                      }
                      window.open(
                        `${process.env.REACT_APP_CONSUMER_URL}`,
                        "_blank"
                      );
                    }}
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <img
                      src={"/assets/footer-logo.png"}
                      style={{ width: 125, borderRadius: 1.5 }}
                    />
                  </Button>
                </Box>
              </Box>
            </Stack>
          ) : (
            <>
              {isCompleteSubmission ? (
                <Stack
                  spacing={3}
                  sx={{
                    py: 1,
                    maxWidth: 380,
                    width: "100%",
                    alignItems: "center",
                    webkitOverflowScrolling: "touch",
                    overflowY: "auto",
                    margin: "0 auto",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "rgba(0,0,0,0.78)",
                      padding: "0 6px",
                      margin: "0 auto",
                      maxWidth: 380,
                      width: "80%",
                      marginBottom: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: "0.85rem",
                      }}
                    >
                      {form.complete_submit_title
                        ? form.complete_submit_title
                        : "送信が完了しました"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      maxWidth: 380,
                      width: "80%",
                      alignItems: "center",
                      alignSelf: "center",
                      bottom: "12px",
                      margin: "0 auto",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "#fff", whiteSpace: "pre-line" }}
                    >
                      {form.complete_submit_description}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      maxWidth: 380,
                      width: "100%",
                      alignItems: "center",
                      alignSelf: "center",
                      bottom: "12px",
                      margin: "0 auto",
                    }}
                  >
                    <Button
                      id="closeButton"
                      sx={{
                        backgroundColor: "#ddd",
                        color: "#fff",
                        borderRadius: "100px",
                        padding: "6px 12px",
                        margin: "0 auto",
                        display: "block",
                        width: "87%",
                        height: 50,
                        "&:hover": {
                          backgroundColor: "#ddd",
                        },
                      }}
                      onClick={() => {
                        if (isIFrame) {
                          window.parent.postMessage(
                            { action: "closeVideoChoose" },
                            "*"
                          );
                        }
                        handleFormModalClose();
                      }}
                    >
                      <Typography variant="body2" sx={{ color: "#333" }}>
                        閉じる
                      </Typography>
                    </Button>
                  </Box>
                </Stack>
              ) : (
                <Stack
                  spacing={1}
                  className="custom-scrollbar"
                  sx={{
                    py: 1,
                    maxWidth: 380,
                    width: "100%",
                    alignItems: "center",
                    webkitOverflowScrolling: "touch",
                    overflowY: "auto",
                    margin: "0 auto",
                  }}
                  onTouchMove={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Box sx={{ width: "100%", overflow: "hidden" }}>
                    <Stepper
                      nonLinear
                      alternativeLabel
                      activeStep={selectedFormStep}
                    >
                      {steps &&
                        steps.length > 1 &&
                        steps.map((label, index) => (
                          <Step>
                            <StepButton
                              sx={{
                                "& .MuiStepLabel-iconContainer": {
                                  "& .MuiStepIcon-root": {
                                    color:
                                      filledFormStep && index <= filledFormStep
                                        ? "rgba(255, 255, 255, 0.3)"
                                        : "rgba(255, 255, 255, 0)",
                                    "&.Mui-active": {
                                      color: "#1976d2",
                                    },
                                  },
                                  "& .MuiStepIcon-text": {
                                    fill: "#fff",
                                  },
                                },
                                "& .MuiStepLabel-label": {
                                  "&.Mui-active": {
                                    color: "#fff",
                                    fontSize: 11,
                                  },
                                  color: "#fff",
                                  fontSize: 11,
                                },
                              }}
                              onClick={() => {
                                if (
                                  filledFormStep &&
                                  index <= filledFormStep &&
                                  handleFormStepProgressBar
                                ) {
                                  handleFormStepProgressBar(index, formId);
                                }
                              }}
                            >
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                    </Stepper>
                  </Box>

                  <Stack
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="center"
                    alignSelf="center"
                    sx={{ maxWidth: 380, width: "100%", paddingTop: 2 }}
                  >
                    {form.is_title_displayed && (
                      <Box
                        sx={{
                          padding: "0 6px",
                          margin: "0 auto",
                          maxWidth: 380,
                          width: "80%",
                          marginBottom: 2,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: "0.85rem",
                          }}
                        >
                          {form.title}
                        </Typography>
                      </Box>
                    )}

                    <Box
                      sx={{
                        padding: "0 6px",
                        margin: "0 auto",
                        maxWidth: 380,
                        width: "80%",
                        marginBottom: 2,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#fff",
                          textAlign: "left",
                          fontSize: "0.85rem",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {form.description}
                      </Typography>
                    </Box>
                  </Stack>

                  {form.order.map((fieldId: string, fieldIndex: number) => (
                    <FieldItem
                      key={fieldId}
                      field={fields[fieldId]}
                      value={fieldValues[fieldId] as any}
                      onChange={(value) => handleInputChange(fieldId, value)}
                      error={emptyErrorFieldIds.includes(fieldId)}
                      fieldValues={fieldValues}
                      fieldErrors={fieldErrors}
                      errorMessages={errorMessages}
                      handleChangeFieldErrors={handleChangeFieldErrors}
                      handleChangeErrorMessages={handleChangeErrorMessages}
                    />
                  ))}
                  <Box
                    sx={{
                      maxWidth: 380,
                      width: "100%",
                      alignItems: "center",
                      alignSelf: "center",
                      bottom: "12px",
                      margin: "0 auto",
                    }}
                  >
                    <Button
                      id="submitButton"
                      sx={{
                        backgroundColor: form.send_color || "#66c5c7",
                        color: "#fff",
                        borderRadius: "100px",
                        padding: "6px 12px",
                        margin: "0 auto",
                        display: "block",
                        width: "87%",
                        height: 50,
                        "&:hover": {
                          backgroundColor: form.send_color || "#66c5c7",
                        },
                      }}
                      onClick={() => {
                        if (!loading) {
                          handleSubmit();
                        }
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <Typography variant="body2" sx={{ color: "#fff" }}>
                          {formsOrdered &&
                          selectedFormStep !== undefined &&
                          filledFormStep &&
                          formsOrdered?.length > 0 &&
                          selectedFormStep < filledFormStep
                            ? "更新する"
                            : form.button_text || "送信する"}
                        </Typography>
                      )}
                    </Button>
                    <Box sx={commonStyles.footerBox}>
                      <Box
                        style={{
                          fontSize: 13,
                        }}
                      >
                        Powered by &nbsp;
                      </Box>
                      <Button
                        onClick={() => {
                          if (videoRef.current) {
                            videoRef.current.pause();
                          }
                          window.open(
                            `${process.env.REACT_APP_CONSUMER_URL}`,
                            "_blank"
                          );
                        }}
                        sx={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <img
                          src={"/assets/footer-logo.png"}
                          style={{ width: 125, borderRadius: 1.5 }}
                        />
                      </Button>
                    </Box>
                  </Box>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Paper>
    </>
  );
}

const commonStyles = {
  footerBox: {
    position: "relative",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .15s 0s cubic-bezier(.25,.46,.45,.94)",
    borderRadius: "2rem",
    height: "2.6rem",
    padding: "1rem 0",
    fontWeight: 500,
    color: "#fff",
    textDecoration: "none",
    display: "inline-flex",
    width: "100%",
    opacity: 0.5,
    marginTop: 2,
  },
};

const orderDetailStyles = {
  orderDetails: {
    maxWidth: "340px",
    margin: "0 auto",
    paddingRight: "15px",
    paddingLeft: "15px",
    fontFamily: "'Arial', sans-serif",
  },
  section: {
    background: "#f9f9f9",
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "16px",
    marginBottom: "24px",
  },
  sectionHeader: {
    fontSize: "16px",
    marginTop: "0px",
    marginBottom: "6px",
    color: "#333",
  },
  itemList: {
    gap: "8px",
  },
  item: {
    margin: "0",
    fontSize: "12px",
  },
  cautionContent: {
    fontSize: "12px",
    color: "#555",
    background: "#fff8e1",
    padding: "12px",
    border: "1px solid #e6d3b3",
    borderRadius: "8px",
  },
};
