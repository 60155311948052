import { useMemo } from "react";

import { paths } from "src/routes/paths";
import Iconify from "src/components/Iconify";
import { useAuthData } from "src/auth/firebase/AuthProvider";

const icon = (name: string) => <Iconify icon={name} />;

const ICONS = {
  dashboard: icon("material-symbols:dashboard"),
  videoChoose: icon("ri:video-fill"),
  analytics: icon("ion:analytics-sharp"),
  form: icon("mdi:form-outline"),
  settings: icon("material-symbols:settings"),
};

export function useNavData() {
  const { user } = useAuthData();
  const data = useMemo(
    () => [
      user?.dashboard_type === "only-analytics"
        ? {
            subheader: "overview",
            items: [
              {
                title: "分析",
                path: paths.dashboard.analytics,
                icon: ICONS.analytics,
              },
            ],
          }
        : {
            subheader: "overview",
            items: [
              {
                title: "動画チャットボット",
                path: paths.dashboard.root,
                icon: ICONS.dashboard,
              },
              // {
              //   title: "プロジェクト",
              //   path: paths.dashboard.videoList,
              //   icon: ICONS.videoChoose,
              // },
              {
                title: "フォーム",
                path: paths.dashboard.formDataList,
                icon: ICONS.form,
              },
              {
                title: "分析",
                path: paths.dashboard.analytics,
                icon: ICONS.analytics,
              },
              // {
              //   title: "設定",
              //   path: paths.dashboard.settings,
              //   icon: ICONS.settings,
              // },
            ],
          },
    ],
    []
  );

  return data;
}
