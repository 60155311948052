import Stack from "@mui/material/Stack";
import Paper, { PaperProps } from "@mui/material/Paper";
import { FormField } from "src/types/video";
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Iconify from "src/components/Iconify";
import { useRef, useState } from "react";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { Tooltip } from "react-tooltip";
import { prefectures } from "src/utils/prefecture";

type SeparatedProps = {
  familyName: string;
  givenName: string;
};

type NameFuriganaProps = {
  kanji: string;
  kana: string;
};

type SeparatedFuriganaProps = {
  familyNameKanji: string;
  givenNameKanji: string;
  familyNameKana: string;
  givenNameKana: string;
};

type SeparatedCreditCardProps = {
  cardNumber: string;
  expiryDate: Date | null;
  cardHolder: string;
  securityCode: string;
};

type Props = PaperProps & {
  field: FormField;
  value:
    | string
    | number
    | boolean
    | NameFuriganaProps
    | SeparatedProps
    | SeparatedFuriganaProps
    | SeparatedCreditCardProps
    | Date;
  onChange: (
    value:
      | string
      | number
      | boolean
      | NameFuriganaProps
      | SeparatedProps
      | SeparatedFuriganaProps
      | SeparatedCreditCardProps
      | Date
      | null
  ) => void;
  error: boolean;
  fieldValues: any;
  fieldErrors: any;
  errorMessages: any;
  handleChangeFieldErrors: any;
  handleChangeErrorMessages: any;
};

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validateTelNumber = (tel: string) => {
  const re = /^(0\d{1,4}\d{1,4}\d{4})$/;
  const fullWidthRegEx = /[０-９]/;
  if (fullWidthRegEx.test(tel)) {
    return "半角で入力してください";
  }
  return re.test(tel) ? "" : "無効な電話番号形式です";
};

const validatePassword = (password: string) => {
  return password.length >= 8;
};
const validatePostalCode = (postalCode: string) => {
  const re = /^\d{7}$/;
  const fullWidthRegEx = /[０-９]/;
  if (fullWidthRegEx.test(postalCode)) {
    return "半角で入力してください";
  }
  return re.test(postalCode) ? "" : "無効な郵便番号形式です";
};

const createMarkup = (htmlString: string) => {
  return { __html: htmlString };
};

const hiraganaToKatakana = (str: string) => {
  return str.replace(/[\u3041-\u3096]/g, (match) => {
    return String.fromCharCode(match.charCodeAt(0) + 0x60);
  });
};
const validateKatakana = (value: string) => {
  const katakanaRegex = /^[\u30A0-\u30FF]+$/;
  return katakanaRegex.test(value) ? "" : "カタカナで入力してください";
};

const validateCreditCardNumber = (number: string) => {
  let sum = 0;
  let shouldDouble = false;
  for (let i = number.length - 1; i >= 0; i--) {
    let digit = parseInt(number.charAt(i));
    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }
    sum += digit;
    shouldDouble = !shouldDouble;
  }
  return sum % 10 === 0;
};

const validateSecurityCode = (code: string) => {
  const re = /^[0-9]{3,4}$/;
  return re.test(code);
};

const validateExpiryDate = (date: Date | null) => {
  if (!date) return false;
  const today = new Date();
  const expiryDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return expiryDate >= today;
};

export default function FieldItem({
  field,
  value,
  onChange,
  error,
  fieldValues,
  fieldErrors,
  errorMessages,
  handleChangeFieldErrors,
  handleChangeErrorMessages,
}: Props) {
  const [isHelperTooltipOpen, setIsHelperTooltipOpen] =
    useState<boolean>(false);

  const [errorChecksStarted, setErrorChecksStarted] = useState<{
    [key: string]: boolean;
  }>({});

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFocus = () => {
    setIsHelperTooltipOpen(true);

    // 既存のタイマーをクリア
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // 新しいタイマーを設定
    timeoutRef.current = setTimeout(() => {
      setIsHelperTooltipOpen(false);
      timeoutRef.current = null;
    }, 3500);
  };

  const handleBlur = () => {
    setIsHelperTooltipOpen(false);

    // タイマーをクリア
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };
  const handleBlurError = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string
  ) => {
    const value = e.target.value;
    const messages: string[] = [];

    if (
      field.required &&
      !value &&
      field.type !== "names_separated_furigana" &&
      field.type !== "credit_card"
    ) {
      messages.push("必須項目です");
    }

    if (field.type === "email" && !validateEmail(value)) {
      messages.push("無効な Email 形式です");
    }
    if (field.type === "tel" && value) {
      const telValidationMessage = validateTelNumber(value);
      if (telValidationMessage) {
        messages.push(telValidationMessage);
      }
    }
    if (field.type === "password" && !validatePassword(value)) {
      messages.push("8文字以上で入力してください");
    }

    if (field.fieldName === "postal_code" && value) {
      const postalCodeValidationMessage = validatePostalCode(value);
      if (postalCodeValidationMessage) {
        messages.push(postalCodeValidationMessage);
      }
    }

    if (field.type === "names_separated_furigana") {
      if (
        fieldId === `familyNameKanji-${field.id}` &&
        (!value || value === "")
      ) {
        messages.push("必須項目です");
      }
      if (
        fieldId === `givenNameKanji-${field.id}` &&
        (!value || value === "")
      ) {
        messages.push("必須項目です");
      }
      if (
        fieldId === `familyNameKana-${field.id}` &&
        (!value || value === "")
      ) {
        messages.push("必須項目です");
      } else if (
        fieldId === `familyNameKana-${field.id}` &&
        validateKatakana(value)
      ) {
        messages.push(validateKatakana(value));
      }
      if (fieldId === `givenNameKana-${field.id}` && (!value || value === "")) {
        messages.push("必須項目です");
      } else if (
        fieldId === `givenNameKana-${field.id}` &&
        validateKatakana(value)
      ) {
        messages.push(validateKatakana(value));
      }
    }

    if (field.type === "credit_card") {
      if (fieldId === `creditCardNumber-${field.id}`) {
        if (!value) {
          messages.push("必須項目です");
        } else if (!validateCreditCardNumber(value)) {
          messages.push("無効なクレジットカード番号です");
        } else {
          handleChangeFieldErrors(`creditCardNumber-${field.id}`, false);
          handleChangeErrorMessages(`creditCardNumber-${field.id}`, []);
        }
      }
      if (fieldId === `creditCardHolder-${field.id}`) {
        if (!value) {
          messages.push("必須項目です");
        } else {
          handleChangeFieldErrors(`creditCardHolder-${field.id}`, false);
          handleChangeErrorMessages(`creditCardHolder-${field.id}`, []);
        }
      }

      if (fieldId === `creditCardSecurityCode-${field.id}`) {
        if (!value) {
          messages.push("必須項目です");
        } else if (!validateSecurityCode(value)) {
          messages.push("無効なセキュリティコードです");
        } else {
          handleChangeFieldErrors(`creditCardSecurityCode-${field.id}`, false);
          handleChangeErrorMessages(`creditCardSecurityCode-${field.id}`, []);
        }
      }
    }

    handleChangeFieldErrors(fieldId, messages.length > 0);

    handleChangeErrorMessages(fieldId, messages);

    if (messages.length > 0) {
      setErrorChecksStarted((prev) => ({
        ...prev,
        [fieldId]: true,
      }));
    }
  };

  const handleChange = (
    valueOrEvent:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | Date
      | null,
    fieldId: string
  ) => {
    let value: string | Date | null;

    if (valueOrEvent instanceof Date || valueOrEvent === null) {
      // Date objectまたはnullの場合（DatePickerからの入力）
      value = valueOrEvent;
    } else {
      // ChangeEventの場合（通常のテキスト入力など）
      value = valueOrEvent.target.value;
    }

    const messages: string[] = [];

    if (errorChecksStarted[fieldId]) {
      if (
        field.required &&
        !value &&
        field.type !== "names_separated_furigana" &&
        field.type !== "credit_card"
      ) {
        messages.push("必須項目です");
      }

      if (
        field.type === "email" &&
        typeof value === "string" &&
        !validateEmail(value)
      ) {
        messages.push("無効な Email 形式です");
      }

      if (field.type === "tel" && typeof value === "string" && value) {
        const telValidationMessage = validateTelNumber(value);
        if (telValidationMessage) {
          messages.push(telValidationMessage);
        }
      }

      if (
        field.type === "password" &&
        typeof value === "string" &&
        !validatePassword(value)
      ) {
        messages.push("8文字以上で入力してください");
      }

      if (
        field.fieldName === "postal_code" &&
        typeof value === "string" &&
        value
      ) {
        const postalCodeValidationMessage = validatePostalCode(value);
        if (postalCodeValidationMessage) {
          messages.push(postalCodeValidationMessage);
        }
      }

      if (field.type === "names_separated_furigana") {
        if (
          fieldId === `familyNameKanji-${field.id}` &&
          (!value || value === "")
        ) {
          messages.push("必須項目です");
        } else if (fieldId === `familyNameKanji-${field.id}`) {
          handleChangeFieldErrors(`familyNameKanji-${field.id}`, false);
          handleChangeFieldErrors(`familyNameKana-${field.id}`, false);

          handleChangeErrorMessages(`familyNameKanji-${field.id}`, []);
          handleChangeErrorMessages(`familyNameKana-${field.id}`, []);
        }

        if (
          fieldId === `givenNameKanji-${field.id}` &&
          (!value || value === "")
        ) {
          messages.push("必須項目です");
        } else if (fieldId === `givenNameKanji-${field.id}`) {
          handleChangeFieldErrors(`givenNameKanji-${field.id}`, false);
          handleChangeFieldErrors(`givenNameKana-${field.id}`, false);

          handleChangeErrorMessages(`givenNameKanji-${field.id}`, []);
          handleChangeErrorMessages(`givenNameKana-${field.id}`, []);
        }
      }

      if (field.type === "credit_card") {
        if (fieldId === `creditCardNumber-${field.id}`) {
          if (!value) {
            messages.push("必須項目です");
          } else if (
            typeof value === "string" &&
            !validateCreditCardNumber(value)
          ) {
            messages.push("無効なクレジットカード番号です");
          } else {
            handleChangeFieldErrors(`creditCardNumber-${field.id}`, false);
            handleChangeErrorMessages(`creditCardNumber-${field.id}`, []);
          }
        }

        if (fieldId === `creditCardHolder-${field.id}`) {
          if (!value) {
            messages.push("必須項目です");
          } else {
            handleChangeFieldErrors(`creditCardHolder-${field.id}`, false);
            handleChangeErrorMessages(`creditCardHolder-${field.id}`, []);
          }
        }
        if (fieldId === `creditCardExpiryDate-${field.id}`) {
          if (!value) {
            messages.push("必須項目です");
          } else if (value instanceof Date) {
            if (!validateExpiryDate(value)) {
              messages.push("無効な有効期限です");
            }
          } else {
            messages.push("無効な日付形式です");
          }

          if (messages.length === 0) {
            handleChangeFieldErrors(`creditCardExpiryDate-${field.id}`, false);
            handleChangeErrorMessages(`creditCardExpiryDate-${field.id}`, []);
          }
        }

        if (fieldId === `creditCardSecurityCode-${field.id}`) {
          if (!value) {
            messages.push("必須項目です");
          } else if (
            typeof value === "string" &&
            !validateSecurityCode(value)
          ) {
            messages.push("無効なセキュリティコードです");
          } else {
            handleChangeFieldErrors(
              `creditCardSecurityCode-${field.id}`,
              false
            );
            handleChangeErrorMessages(`creditCardSecurityCode-${field.id}`, []);
          }
        }
      }

      handleChangeFieldErrors(fieldId, messages.length > 0);

      handleChangeErrorMessages(fieldId, messages);
    }

    onChange(value);
  };

  //Done
  const renderTextField = (
    <TextField
      sx={{
        width: "100%",
        borderRadius: 2,
        backgroundColor: "#fff!important",
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff", // 白背景を設定
          "&:before, &:after": { borderBottom: "none" }, // 下線を非表示に
          "&:hover:before": { borderBottom: "none !important" }, // ホバー時の下線を非表示に
        },
        "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before":
          {
            borderBottom: "none", // 下線を非表示に（非filledバリアント向けのスタイル調整）
          },
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },
      }}
      inputProps={{
        maxLength: 200,
      }}
      required={field.required}
      label={field.label}
      size="small"
      variant="filled"
      type="text"
      value={value || ""}
      // onChange={(e) => onChange(e.target.value)}
      onChange={(e) => handleChange(e, field.id)}
      onBlur={(e) => handleBlurError(e, field.id)}
      error={error}
    />
  );

  const renderNumberField = (
    <TextField
      sx={{
        width: "100%",
        borderRadius: 2,
        backgroundColor: "#fff!important",
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff", // 白背景を設定
          "&:before, &:after": { borderBottom: "none" }, // 下線を非表示に
          "&:hover:before": { borderBottom: "none !important" }, // ホバー時の下線を非表示に
        },
        "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before":
          {
            borderBottom: "none", // 下線を非表示に（非filledバリアント向けのスタイル調整）
          },
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },
      }}
      required={field.required}
      label={field.label}
      size="small"
      variant="filled"
      inputProps={{
        inputMode: "numeric", // Brings up numeric keyboard on mobile
        pattern: "[0-9]*", // Helps screen readers understand the expected input
      }}
      value={value || ""}
      onChange={(e) => handleChange(e, field.id)}
      onBlur={(e) => handleBlurError(e, field.id)}
      error={error}
    />
  );

  const renderPostalCodeField = (
    <TextField
      sx={{
        width: "100%",
        borderRadius: 2,
        backgroundColor: "#fff!important",
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff",
          "&:before, &:after": { borderBottom: "none" },
          "&:hover:before": { borderBottom: "none !important" },
        },
        "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before":
          {
            borderBottom: "none",
          },
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },
      }}
      inputProps={{
        maxLength: 200,
      }}
      required={field.required}
      label={field.label}
      size="small"
      variant="filled"
      type="text"
      value={value || ""}
      onChange={(e) => handleChange(e, field.id)}
      onBlur={(e) => handleBlurError(e, field.id)}
      error={error}
    />
  );

  const renderNameFuriganaField = (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <TextField
          id={`nameKanji-${field.id}`}
          sx={{
            width: "100%",
            borderRadius: 2,
            backgroundColor: "#fff!important",
            "& .MuiInputLabel-root": {
              color: "#949494!important",
            },
            "& .MuiFilledInput-underline:before": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:after": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:hover:before": {
              borderBottom: "none!important",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:hover:after": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-root": {
              backgroundColor: "#fff!important",
              borderRadius: 2,
            },
          }}
          inputProps={{
            maxLength: 200,
          }}
          label={"氏名 (漢字)"}
          size="small"
          variant="filled"
          value={(value as NameFuriganaProps)?.kanji || ""}
          onChange={(e) => {
            const values: NameFuriganaProps = (value as NameFuriganaProps) || {
              kanji: "",
              kana: "",
            };
            values.kanji = e.target.value;

            if (e.target.value.match(/^[\u3040-\u309F]+$/)) {
              values.kana = hiraganaToKatakana(e.target.value);
            }
            handleChange(e, `nameKanji-${field.id}`);
            onChange(values);
          }}
          required={field.required}
          onBlur={(e) => handleBlurError(e, `nameKanji-${field.id}`)}
          error={error && !(value as NameFuriganaProps)?.kanji}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: "16px!important" }}
      >
        <TextField
          id={`nameKana-${field.id}`}
          sx={{
            width: "100%",
            borderRadius: 2,
            backgroundColor: "#fff!important",
            "& .MuiInputLabel-root": {
              color: "#949494!important",
            },
            "& .MuiFilledInput-underline:before": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:after": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:hover:before": {
              borderBottom: "none!important",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:hover:after": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-root": {
              backgroundColor: "#fff!important",
              borderRadius: 2,
            },
          }}
          inputProps={{
            maxLength: 200,
          }}
          label={"氏名 (フリガナ)"}
          size="small"
          variant="filled"
          value={(value as NameFuriganaProps)?.kana || ""}
          onChange={(e) => {
            const values: NameFuriganaProps = (value as NameFuriganaProps) || {
              kanji: "",
              kana: "",
            };
            values.kana = e.target.value;
            onChange(values);
          }}
          required={field.required}
          onBlur={(e) => handleBlurError(e, `nameKana-${field.id}`)}
          error={error && !(value as NameFuriganaProps)?.kana}
        />
      </Stack>
    </>
  );
  const renderNameSeparatedField = (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <TextField
        sx={{
          width: "48%",
          borderRadius: 2,

          backgroundColor: "#fff!important",
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },
          "& .MuiFilledInput-underline:before": {
            borderBottom: "none", // フォーカスされていないときの下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-underline:after": {
            borderBottom: "none", // フォーカスされたときの下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-underline:hover:before": {
            borderBottom: "none!important", // ホバー時の下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-underline:hover:after": {
            borderBottom: "none", // ホバー時の下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-root": {
            backgroundColor: "#fff!important",
            borderRadius: 2,
          },
        }}
        inputProps={{
          maxLength: 200,
        }}
        label={"姓"}
        size="small"
        variant="filled"
        value={(value as SeparatedProps)?.familyName || ""}
        onChange={(e) => {
          const values: SeparatedProps = (value as SeparatedProps) || {
            familyName: "",
            givenName: "",
          };
          values.familyName = e.target.value;
          onChange(values);
        }}
        required={field.required}
        error={error && !(value as SeparatedProps)?.familyName}
      />
      <TextField
        sx={{
          width: "48%",
          borderRadius: 2,
          backgroundColor: "#fff!important",
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },

          "& .MuiFilledInput-underline:before": {
            borderBottom: "none", // フォーカスされていないときの下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-underline:after": {
            borderBottom: "none", // フォーカスされたときの下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-underline:hover:before": {
            borderBottom: "none", // ホバー時の下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-underline:hover:after": {
            borderBottom: "none", // ホバー時の下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-root": {
            backgroundColor: "#fff!important",
            borderRadius: 2,
          },
        }}
        label={"名"}
        inputProps={{
          maxLength: 200,
        }}
        size="small"
        variant="filled"
        value={(value as SeparatedProps)?.givenName || ""}
        onChange={(e) => {
          const values: SeparatedProps = (value as SeparatedProps) || {
            familyName: "",
            givenName: "",
          };
          values.givenName = e.target.value;
          onChange(values);
        }}
        required={field.required}
        error={error && !(value as SeparatedProps)?.givenName}
      />
    </Stack>
  );
  //姓名カタカナフィールド
  const renderNameSeparatedFuriganaField = (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <TextField
          id={`familyNameKanji-${field.id}`}
          sx={{
            width: "48%",
            borderRadius: 2,
            backgroundColor: "#fff!important",
            "& .MuiInputLabel-root": {
              color: "#949494!important",
            },
            "& .MuiFilledInput-underline:before": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:after": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:hover:before": {
              borderBottom: "none!important",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:hover:after": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-root": {
              backgroundColor: "#fff!important",
              borderRadius: 2,
            },
          }}
          inputProps={{
            maxLength: 200,
          }}
          label={"姓"}
          size="small"
          variant="filled"
          value={(value as SeparatedFuriganaProps)?.familyNameKanji || ""}
          onChange={(e) => {
            const values: SeparatedFuriganaProps =
              (value as SeparatedFuriganaProps) || {
                familyNameKanji: "",
                givenNameKanji: "",
                familyNameKana: "",
                givenNameKana: "",
              };
            values.familyNameKanji = e.target.value;
            if (e.target.value.match(/^[\u3040-\u309F]+$/)) {
              values.familyNameKana = hiraganaToKatakana(e.target.value);
            }
            handleChange(e, `familyNameKanji-${field.id}`);

            onChange(values);
          }}
          required={field.required}
          onBlur={(e) => handleBlurError(e, `familyNameKanji-${field.id}`)}
          error={error && !(value as SeparatedFuriganaProps)?.familyNameKanji}
        />
        <TextField
          id={`givenNameKanji-${field.id}`}
          sx={{
            width: "48%",
            borderRadius: 2,
            backgroundColor: "#fff!important",
            "& .MuiInputLabel-root": {
              color: "#949494!important",
            },
            "& .MuiFilledInput-underline:before": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:after": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:hover:before": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:hover:after": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-root": {
              backgroundColor: "#fff!important",
              borderRadius: 2,
            },
          }}
          label={"名"}
          inputProps={{
            maxLength: 200,
          }}
          size="small"
          variant="filled"
          value={(value as SeparatedFuriganaProps)?.givenNameKanji || ""}
          onChange={(e) => {
            const values: SeparatedFuriganaProps =
              (value as SeparatedFuriganaProps) || {
                familyNameKanji: "",
                givenNameKanji: "",
                familyNameKana: "",
                givenNameKana: "",
              };
            values.givenNameKanji = e.target.value;
            if (e.target.value.match(/^[\u3040-\u309F]+$/)) {
              values.givenNameKana = hiraganaToKatakana(e.target.value);
            }
            handleChange(e, `givenNameKanji-${field.id}`);
            onChange(values);
          }}
          required={field.required}
          onBlur={(e) => handleBlurError(e, `givenNameKanji-${field.id}`)}
          error={error && !(value as SeparatedFuriganaProps)?.givenNameKanji}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: "16px!important" }}
      >
        <TextField
          id={`familyNameKana-${field.id}`}
          sx={{
            width: "48%",
            borderRadius: 2,
            backgroundColor: "#fff!important",
            "& .MuiInputLabel-root": {
              color: "#949494!important",
            },
            "& .MuiFilledInput-underline:before": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:after": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:hover:before": {
              borderBottom: "none!important",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:hover:after": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-root": {
              backgroundColor: "#fff!important",
              borderRadius: 2,
            },
          }}
          inputProps={{
            maxLength: 200,
          }}
          label={"セイ"}
          size="small"
          variant="filled"
          value={(value as SeparatedFuriganaProps)?.familyNameKana || ""}
          onChange={(e) => {
            const values: SeparatedFuriganaProps =
              (value as SeparatedFuriganaProps) || {
                familyNameKanji: "",
                givenNameKanji: "",
                familyNameKana: "",
                givenNameKana: "",
              };
            values.familyNameKana = e.target.value;
            handleChange(e, `familyNameKana-${field.id}`);
            onChange(values);
          }}
          required={field.required}
          onBlur={(e) => handleBlurError(e, `familyNameKana-${field.id}`)}
          error={error && !(value as SeparatedFuriganaProps)?.familyNameKana}
        />
        <TextField
          id={`givenNameKana-${field.id}`}
          sx={{
            width: "48%",
            borderRadius: 2,
            backgroundColor: "#fff!important",
            "& .MuiInputLabel-root": {
              color: "#949494!important",
            },
            "& .MuiFilledInput-underline:before": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:after": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:hover:before": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-underline:hover:after": {
              borderBottom: "none",
              textDecoration: "none",
            },
            "& .MuiFilledInput-root": {
              backgroundColor: "#fff!important",
              borderRadius: 2,
            },
          }}
          label={"メイ"}
          inputProps={{
            maxLength: 200,
          }}
          size="small"
          variant="filled"
          value={(value as SeparatedFuriganaProps)?.givenNameKana || ""}
          onChange={(e) => {
            const values: SeparatedFuriganaProps =
              (value as SeparatedFuriganaProps) || {
                familyNameKanji: "",
                givenNameKanji: "",
                familyNameKana: "",
                givenNameKana: "",
              };
            values.givenNameKana = e.target.value;
            handleChange(e, `givenNameKana-${field.id}`);
            onChange(values);
          }}
          required={field.required}
          onBlur={(e) => handleBlurError(e, `givenNameKana-${field.id}`)}
          error={error && !(value as SeparatedFuriganaProps)?.givenNameKana}
        />
      </Stack>
    </>
  );
  const renderEmailField = (
    <>
      <TextField
        sx={{
          width: "100%",
          borderRadius: 2,
          backgroundColor: "#fff!important",
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },

          "& .MuiFilledInput-underline:before": {
            borderBottom: "none", // フォーカスされていないときの下線を消す
          },
          "& .MuiFilledInput-underline:after": {
            borderBottom: "none", // フォーカスされたときの下線を消す
          },
          "& .MuiFilledInput-underline:hover:before": {
            borderBottom: "none", // ホバー時の下線を消す
          },
          "& .MuiFilledInput-underline:hover:after": {
            borderBottom: "none", // ホバー時の下線を消す
          },
          "& .MuiFilledInput-root": {
            backgroundColor: "#fff!important",
            borderRadius: 2,
          },
        }}
        inputProps={{
          maxLength: 200,
        }}
        label={field.label}
        size="small"
        variant="filled"
        type="email"
        value={value || ""}
        required={field.required}
        onChange={(e) => handleChange(e, field.id)}
        onBlur={(e) => handleBlurError(e, field.id)}
        error={error}
      />
    </>
  );

  const renderTelField = (
    <TextField
      sx={{
        width: "100%",
        borderRadius: 2,
        backgroundColor: "#fff!important",
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },

        "& .MuiFilledInput-underline:before": {
          borderBottom: "none", // フォーカスされていないときの下線を消す
          textDecoration: "none",
        },
        "& .MuiFilledInput-underline:after": {
          borderBottom: "none", // フォーカスされたときの下線を消す
          textDecoration: "none",
        },
        "& .MuiFilledInput-underline:hover:before": {
          borderBottom: "none", // ホバー時の下線を消す
          textDecoration: "none",
        },
        "& .MuiFilledInput-underline:hover:after": {
          borderBottom: "none", // ホバー時の下線を消す
          textDecoration: "none",
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff!important",
          borderRadius: 2,
        },
      }}
      inputProps={{
        maxLength: 200,
      }}
      label={field.label}
      size="small"
      variant="filled"
      type="tel"
      value={value || ""}
      required={field.required}
      onChange={(e) => handleChange(e, field.id)}
      onBlur={(e) => handleBlurError(e, field.id)}
      error={error}
    />
  );

  const renderCheckboxField = (
    <FormControlLabel
      required={field.required}
      control={
        <Checkbox
          checked={!!value} // チェック状態をバインド
          onChange={(e) => onChange(e.target.checked)}
          icon={
            <span
              style={{
                borderRadius: 6,
                width: 24,
                height: 24,
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "2px",
                ...(error && {
                  borderColor: "red",
                  borderWidth: 2,
                  borderStyle: "solid",
                }),
              }}
            />
          } // チェックされていない時のカスタムアイコン
          checkedIcon={
            <span
              style={{
                borderRadius: 6,
                width: 24,
                height: 24,
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "2px",
              }}
            >
              <Iconify
                icon={"ic:round-check"}
                sx={{ color: "#333" }}
                width={20}
                height={20}
              />
            </span>
          } // チェックされた時のカスタムアイコン
        />
      }
      // チェックボックスにラベルを追加
      label={
        field.checkboxDescription ? (
          <span
            style={{ fontSize: 12 }}
            dangerouslySetInnerHTML={createMarkup(field.checkboxDescription)}
          />
        ) : (
          <span style={{ fontSize: "0.875rem" }}>{field.label}</span>
        )
      }
      sx={{
        color: "#fff",

        "& .MuiFormControlLabel-label span p": {
          display: "inline",
        },
        "& .MuiFormControlLabel-label span a": {
          color: "#fff",
        },
        "& .MuiFormControlLabel-label span a:hover": {
          color: "#fff",
        },
      }}
    />
  );

  const renderSelectField = (
    <FormControl
      required={field.required}
      variant="filled"
      sx={{ width: "100%", height: "48px" }}
      error={error}
    >
      <InputLabel
        sx={{
          transform: "translate(12px, 13px) scale(1)",
          color: "#949494!important",
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },

          "&.MuiInputLabel-shrink": {
            transform: "translate(12px, 4px) scale(0.75)",
            color: "#949494!important",
          },
        }}
      >
        {field.label}
      </InputLabel>
      <Select
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        sx={{
          backgroundColor: "#fff!important",
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },
          "& .Mui-focused .MuiInputLabel-root": {
            color: "#949494!important",
          },
          height: "48px",
          "&::after": {
            borderBottom: "none",
          },
          "&::before": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled)::before": {
            borderBottom: "none",
          },
        }}
      >
        <MenuItem value="">選択してください</MenuItem>

        {field.options?.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderSelectMultipleField = (
    <FormControl
      required={field.required}
      variant="filled"
      sx={{
        width: "100%",
        ...(!value && { height: "48px" }),
        ...(value && { minHeight: "48px" }),
      }}
      error={error}
    >
      <InputLabel
        sx={{
          transform: "translate(12px, 13px) scale(1)",
          color: "#949494!important",
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },

          "&.MuiInputLabel-shrink": {
            transform: "translate(12px, 4px) scale(0.75)",
            color: "#949494!important",
          },
        }}
      >
        {field.label}
      </InputLabel>
      <Select
        multiple
        value={value && typeof value == "string" ? value.split(",") : []}
        onChange={(e) =>
          onChange(
            Array.isArray(e.target.value) ? e.target.value.join(",") : ""
          )
        }
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} sx={{ height: 22 }} />
            ))}
          </Box>
        )}
        sx={{
          backgroundColor: "#fff!important",
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },
          "& .Mui-focused .MuiInputLabel-root": {
            color: "#949494!important",
          },
          ...(!value && { height: "48px" }),
          ...(value && { minHeight: "48px" }),
          "&::after": {
            borderBottom: "none",
          },
          "&::before": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled)::before": {
            borderBottom: "none",
          },
        }}
      >
        {field.options?.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderRadioButtonField = (
    <FormControl
      required={field.required}
      error={error}
      variant="filled"
      sx={{
        width: "100%",
        height: "48px",
        flexDirection: "row",
        backgroundColor: "#fff!important",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#949494!important",
      }}
    >
      <Box>
        <InputLabel
          sx={{
            transform: "translate(12px, 13px) scale(1)",
            color: "#949494!important",
            "& .MuiInputLabel-root": {
              color: "#949494!important",
            },

            "&.MuiInputLabel-shrink": {
              transform: "translate(12px, 13px) scale(1)",
              color: "#949494!important",
            },
          }}
        >
          {field.label}
        </InputLabel>
      </Box>
      <RadioGroup
        row
        value={value || ""}
        onChange={(e) => handleChange(e, field.id)}
        sx={{
          "&.MuiFormControlLabel-label": {
            color: "#FFFFFF",
            backgroundColor: "#fff!important",
          },
          backgroundColor: "#fff!important",
        }}
      >
        {field.radioOptions?.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option}
            control={
              <Radio
                sx={{
                  color: "#949494",
                  "&.Mui-checked": { color: "#1976d2" },
                  "&.MuiPaper-root": {
                    backgroundColor: "#fff!important",
                  },
                }}
              />
            }
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#666", // ラベルのテキスト色を白に変更
              },
            }}
            label={option}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );

  //都道府県専用選択フィールド
  const renderSelectFieldPrefecture = (
    <FormControl
      required={field.required}
      variant="filled"
      sx={{ width: "100%", height: "48px" }}
      error={error}
    >
      <InputLabel
        sx={{
          transform: "translate(12px, 13px) scale(1)",
          color: "#949494!important",
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },

          "&.MuiInputLabel-shrink": {
            transform: "translate(12px, 4px) scale(0.75)",
            color: "#949494!important",
          },
        }}
      >
        {field.label}
      </InputLabel>
      <Select
        value={value || ""}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxWidth: "200px",
              minWidth: "260px!important",
              width: "200px",
              borderRadius: 3,
            },
          },
        }}
        sx={{
          backgroundColor: "#fff!important",
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },
          "& .Mui-focused .MuiInputLabel-root": {
            color: "#949494!important",
          },
          height: "48px",
          "&::after": {
            borderBottom: "none", // 下線を消すスタイル
          },
          "&::before": {
            borderBottom: "none", // 非フォーカス時の下線を消すスタイル
          },
          "&:hover:not(.Mui-disabled)::before": {
            borderBottom: "none", // ホバー時の下線を消すスタイル
          },
        }}
      >
        <MenuItem
          value=""
          sx={{
            minHeight: "36px",
            fontSize: "14px",
            borderBottom: "0.2px solid #ddd",
          }}
        >
          選択してください
        </MenuItem>

        {prefectures.map((option, index) => (
          <MenuItem
            key={index}
            value={option}
            sx={{
              minHeight: "44px",
              fontSize: "14px",
              borderBottom: "0.2px solid #ddd",
              "&:last-child": {
                borderBottom: "none",
              },
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderDateTimeField = (
    <DateTimePicker
      label={field.label}
      sx={{
        width: "100%",
        borderRadius: 2,
        backgroundColor: "#fff!important",
        color: "#949494!important",
        "& .MuiInputLabel-shrink": {
          color: "#949494!important",
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff!important",
        },
      }}
      slotProps={{
        textField: {
          variant: "filled",
        },
      }}
      value={(value as Date) || null}
      onChange={(date) => onChange(date)}
    />
  );

  const renderDateField = (
    <DatePicker
      label={field.label}
      sx={{
        width: "100%",
        height: "48px",
        backgroundColor: "#fff!important",
        color: "#949494!important",
        "& .MuiInputLabel-shrink": {
          color: "#949494!important",
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff!important",
        },
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },
      }}
      slotProps={{
        textField: {
          variant: "filled",
          required: field.required,
          error: error,
        },
      }}
      minDate={
        field.minDateType === "today"
          ? new Date() // 当日
          : field.minDateType === "daysAfterCurrentDay"
          ? (() => {
              const date = new Date();
              date.setDate(date.getDate() + (field.minDateDaysAfter || 0)); // 当日から `minDateDaysAfter` 日後
              return date;
            })()
          : field.minDateType === "selectedDate"
          ? field.minSelectedDate
            ? new Date(field.minSelectedDate) // `minSelectedDate` を Date 型に変換
            : undefined
          : undefined // 指定なし
      }
      maxDate={
        field.maxDateType === "today"
          ? new Date() // 当日
          : field.maxDateType === "daysAfterCurrentDay"
          ? (() => {
              const date = new Date();
              date.setDate(date.getDate() + (field.maxDateDaysAfter || 0)); // 当日から `maxDateDaysAfter` 日後
              return date;
            })()
          : field.maxDateType === "selectedDate"
          ? field.maxSelectedDate
            ? new Date(field.maxSelectedDate) // `minSelectedDate` を Date 型に変換
            : undefined
          : undefined // 指定なし
      }
      value={
        typeof value === "string" ? new Date(value) : (value as Date) || null
      }
      onChange={(date) => onChange(date)}
    />
  );
  const renderTimeField = (
    <DesktopTimePicker
      label={field.label}
      sx={{
        width: "100%",
        height: "48px",
        backgroundColor: "#fff!important",
        color: "#949494!important",
        "& .MuiInputLabel-shrink": {
          color: "#949494!important",
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff!important",
        },
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },
      }}
      slotProps={{
        textField: {
          variant: "filled",
          required: field.required,
          error: error,
        },
      }}
      value={(value as Date) || null}
      onChange={(date) => onChange(date)}
    />
  );

  const renderTextAreaField = (
    <TextField
      multiline={true}
      sx={{
        width: "100%",
        borderRadius: 2,
        backgroundColor: "#fff!important",
        "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before, & .MuiInput-underline:hover:after, & .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after, & .MuiFilledInput-underline:hover:before, & .MuiFilledInput-underline:hover:after":
          {
            borderBottom: "none",
          },
        "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after, &:hover .MuiFilledInput-underline:before, &:hover .MuiFilledInput-underline:after":
          {
            borderBottom: "none !important",
          },
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },
        "& .MuiInputLabel-shrink": {
          color: "#949494!important",
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff!important",
        },
      }}
      inputProps={{
        maxLength: 1000,
      }}
      rows={3}
      required={field.required}
      label={field.label}
      size="small"
      variant="filled"
      type="text"
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      error={error}
    />
  );

  //誕生日選択フィールド
  const renderBirthday = (
    <DatePicker
      label={field.label}
      openTo="year"
      views={["year", "month", "day"]}
      maxDate={new Date()}
      sx={{
        width: "100%",
        height: "48px",
        backgroundColor: "#fff!important",
        color: "#949494!important",

        "& .MuiInputLabel-shrink": {
          color: "#949494!important",
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff!important",
        },
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },
      }}
      slotProps={{
        textField: {
          variant: "filled",
          required: field.required,
          error: error,
        },
      }}
      value={(value as Date) || null}
      onChange={(date) => onChange(date)}
    />
  );

  //パスワードフィールド
  const renderPassword = (
    <TextField
      sx={{
        width: "100%",
        borderRadius: 2,
        backgroundColor: "#fff!important",
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff", // 白背景を設定
          "&:before, &:after": { borderBottom: "none" }, // 下線を非表示に
          "&:hover:before": { borderBottom: "none !important" }, // ホバー時の下線を非表示に
        },
        "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before":
          {
            borderBottom: "none", // 下線を非表示に（非filledバリアント向けのスタイル調整）
          },
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },
      }}
      inputProps={{
        maxLength: 200,
      }}
      required={field.required}
      label={field.label}
      size="small"
      variant="filled"
      type={showPassword ? "text" : "password"}
      value={value || ""}
      onChange={(e) => handleChange(e, field.id)}
      onBlur={(e) => handleBlurError(e, field.id)}
      error={error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? (
                <Iconify
                  icon={"mdi:eye"}
                  sx={{ color: "#333" }}
                  width={20}
                  height={20}
                />
              ) : (
                <Iconify
                  icon={"mdi:eye-off"}
                  sx={{ color: "#333" }}
                  width={20}
                  height={20}
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );

  const renderCreditCardField = (
    <>
      <TextField
        id={`creditCardNumber-${field.id}`}
        sx={{
          width: "100%",
          borderRadius: 2,
          backgroundColor: "#fff!important",
          "& .MuiFilledInput-root": {
            backgroundColor: "#fff",
            "&:before, &:after": { borderBottom: "none" },
            "&:hover:before": { borderBottom: "none !important" },
            borderRadius: "8px",
          },
          "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before":
            {
              borderBottom: "none",
            },
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },
        }}
        inputProps={{
          maxLength: 200,
        }}
        required={field.required}
        label="クレジットカード番号"
        size="small"
        variant="filled"
        type="text"
        value={(value as SeparatedCreditCardProps)?.cardNumber || ""}
        onChange={(e) => {
          const values: SeparatedCreditCardProps =
            (value as SeparatedCreditCardProps) || {
              cardNumber: "",
              expiryDate: null,
              cardHolder: "",
              securityCode: "",
            };
          values.cardNumber = e.target.value;
          handleChange(e, `creditCardNumber-${field.id}`);
          onChange(values);
        }}
        onBlur={(e) => handleBlurError(e, `creditCardNumber-${field.id}`)}
        error={error && !(value as SeparatedCreditCardProps)?.cardNumber}
      />

      <DatePicker
        label="有効期限"
        openTo="month"
        views={["month", "year"]}
        minDate={new Date("2024-01-01")}
        maxDate={new Date("2035-12-31")}
        format="MM/yy"
        sx={{
          width: "100%",
          height: "48px",
          backgroundColor: "#fff!important",
          color: "#949494!important",

          "& .MuiInputLabel-shrink": {
            color: "#949494!important",
          },
          "& .MuiFilledInput-root": {
            backgroundColor: "#fff!important",
            borderRadius: "8px",
            minHeight: "48px",
          },
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },
          "& .MuiFormControl-root": {
            borderRadius: "8px",
          },
          "& .MuiFilledInput-root::after": {
            borderBottom: "none",
          },
          "& .MuiFilledInput-root::before": {
            borderBottom: "none",
          },
          minHeight: "48px",
          mt: "16px!important",
          borderRadius: "8px",
        }}
        slotProps={{
          textField: {
            variant: "filled",
            required: field.required,
            error: error && !(value as SeparatedCreditCardProps)?.expiryDate,
          },
        }}
        value={(value as SeparatedCreditCardProps)?.expiryDate || null}
        // onChange={(date) => {
        //   const values: SeparatedCreditCardProps =
        //     (value as SeparatedCreditCardProps) || {
        //       cardNumber: "",
        //       expiryDate: null,
        //       cardHolder: "",
        //       securityCode: "",
        //     };
        //   values.expiryDate = date;
        //   onChange(values);
        // }}
        onChange={(date) => {
          const values: SeparatedCreditCardProps =
            (value as SeparatedCreditCardProps) || {
              cardNumber: "",
              expiryDate: null,
              cardHolder: "",
              securityCode: "",
            };
          values.expiryDate = date;
          handleChange(date, `creditCardExpiryDate-${field.id}`);
          onChange(values);
        }}
        // onBlur={(e) => handleBlurError(e, `creditCardExpiryDate-${field.id}`)}
      />

      <TextField
        id={`creditCardHolder-${field.id}`}
        sx={{
          width: "100%",
          borderRadius: 2,
          backgroundColor: "#fff!important",
          "& .MuiFilledInput-root": {
            backgroundColor: "#fff",
            "&:before, &:after": { borderBottom: "none" },
            "&:hover:before": { borderBottom: "none !important" },
            borderRadius: "8px",
          },
          "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before":
            {
              borderBottom: "none",
            },
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },
          mt: "16px!important",
        }}
        inputProps={{
          maxLength: 200,
        }}
        required={field.required}
        label="カード名義人"
        size="small"
        variant="filled"
        type="text"
        value={(value as SeparatedCreditCardProps)?.cardHolder || ""}
        onChange={(e) => {
          const values: SeparatedCreditCardProps =
            (value as SeparatedCreditCardProps) || {
              cardNumber: "",
              expiryDate: null,
              cardHolder: "",
              securityCode: "",
            };
          values.cardHolder = e.target.value;
          handleChange(e, `creditCardHolder-${field.id}`);
          onChange(values);
        }}
        onBlur={(e) => handleBlurError(e, `creditCardHolder-${field.id}`)}
        error={error && !(value as SeparatedCreditCardProps)?.cardHolder}
      />

      <TextField
        id={`creditCardSecurityCode-${field.id}`}
        sx={{
          width: "100%",
          borderRadius: 2,

          backgroundColor: "#fff!important",
          "& .MuiFilledInput-root": {
            backgroundColor: "#fff",
            "&:before, &:after": { borderBottom: "none" },
            "&:hover:before": { borderBottom: "none !important" },
            borderRadius: "8px",
          },
          "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before":
            {
              borderBottom: "none",
            },
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },
          mt: "16px!important",
        }}
        inputProps={{
          maxLength: 200,
        }}
        required={field.required}
        label="セキュリティコード"
        size="small"
        variant="filled"
        type="text"
        value={(value as SeparatedCreditCardProps)?.securityCode || ""}
        onChange={(e) => {
          const values: SeparatedCreditCardProps =
            (value as SeparatedCreditCardProps) || {
              cardNumber: "",
              expiryDate: null,
              cardHolder: "",
              securityCode: "",
            };
          values.securityCode = e.target.value;
          handleChange(e, `creditCardSecurityCode-${field.id}`);
          onChange(values);
        }}
        onBlur={(e) => handleBlurError(e, `creditCardSecurityCode-${field.id}`)}
        error={error && !(value as SeparatedCreditCardProps)?.securityCode}
      />
    </>
  );

  const renderImageField = (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <img
        src={field.imageUrl || ""}
        style={{
          borderRadius: 1.5,
          maxWidth: 280,
          width: "100%",
          height: "auto",
        }}
      />
    </Box>
  );

  return (
    <>
      {field.isConditionalDisplay ? (
        field.conditionFieldValues &&
        field.conditionFieldValues.length > 0 &&
        field.conditionField &&
        fieldValues[field.conditionField] &&
        (fieldValues[field.conditionField].includes(",")
          ? fieldValues[field.conditionField]
              ?.split(",")
              .some((value: any) => field.conditionFieldValues?.includes(value))
          : field.conditionFieldValues.includes(
              fieldValues[field.conditionField]
            )) ? (
          <Paper
            onFocus={handleFocus}
            onBlur={handleBlur}
            onClick={handleFocus}
            id={
              field.type === "names_separated_furigana"
                ? undefined
                : field.fieldName
            }
            sx={{
              maxWidth: 380,
              width: "87%",
              minHeight:
                field.type === "textarea"
                  ? 95
                  : field.type === "names_separated_furigana"
                  ? 112
                  : field.type === "credit_card"
                  ? 240
                  : 48,
              overflow: "hidden",
              position: "relative",
              borderRadius: 2,
              boxShadow: "none",
              backgroundColor: "transparent",
              marginTop: "0px!important",
              marginBottom: "16px!important",
            }}
          >
            <>
              {field.type === "text" &&
                field.fieldName !== "postal_code" &&
                renderTextField}
              {field.type === "text" &&
                field.fieldName === "postal_code" &&
                renderPostalCodeField}
              {field.type === "number" && renderNumberField}
              {field.type === "names_furigana" && renderNameFuriganaField}
              {field.type === "names_separated" && renderNameSeparatedField}
              {field.type === "names_separated_furigana" &&
                renderNameSeparatedFuriganaField}
              {field.type === "email" && renderEmailField}
              {field.type === "radio" && renderRadioButtonField}

              {field.type === "select" &&
                field.fieldName !== "prefecture" &&
                (field.selectMultiple
                  ? renderSelectMultipleField
                  : renderSelectField)}

              {field.type === "select" &&
                field.fieldName === "prefecture" &&
                renderSelectFieldPrefecture}

              {field.type === "checkbox" && renderCheckboxField}
              {field.type === "datetime" && renderDateTimeField}
              {field.type === "date" && renderDateField}
              {field.type === "time" && renderTimeField}
              {field.type === "tel" && renderTelField}
              {field.type === "textarea" && renderTextAreaField}
              {field.type === "birthday" && renderBirthday}
              {field.type === "password" && renderPassword}
              {field.type === "credit_card" && renderCreditCardField}
              {field.type === "image" && renderImageField}
            </>
          </Paper>
        ) : (
          <></>
        )
      ) : (
        <Paper
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={handleFocus}
          id={field.id}
          sx={{
            maxWidth: 380,
            width: "87%",
            minHeight:
              field.type === "textarea"
                ? 95
                : field.type === "names_separated_furigana"
                ? 112
                : field.type === "credit_card"
                ? 240
                : 48,
            overflow: "hidden",
            position: "relative",
            borderRadius: 2,
            boxShadow: "none",
            backgroundColor: "transparent",
            marginTop: "0px!important",
            marginBottom: "16px!important",
          }}
        >
          <>
            {field.type === "text" &&
              field.fieldName !== "postal_code" &&
              renderTextField}
            {field.type === "text" &&
              field.fieldName === "postal_code" &&
              renderPostalCodeField}
            {field.type === "number" && renderNumberField}
            {field.type === "names_furigana" && renderNameFuriganaField}
            {field.type === "names_separated" && renderNameSeparatedField}
            {field.type === "names_separated_furigana" &&
              renderNameSeparatedFuriganaField}
            {field.type === "email" && renderEmailField}
            {field.type === "radio" && renderRadioButtonField}

            {field.type === "select" &&
              field.fieldName !== "prefecture" &&
              (field.selectMultiple
                ? renderSelectMultipleField
                : renderSelectField)}

            {field.type === "select" &&
              field.fieldName === "prefecture" &&
              renderSelectFieldPrefecture}
            {field.type === "checkbox" && renderCheckboxField}
            {field.type === "datetime" && renderDateTimeField}
            {field.type === "date" && renderDateField}
            {field.type === "time" && renderTimeField}
            {field.type === "tel" && renderTelField}
            {field.type === "textarea" && renderTextAreaField}
            {field.type === "birthday" && renderBirthday}
            {field.type === "password" && renderPassword}
            {field.type === "credit_card" && renderCreditCardField}
            {field.type === "image" && renderImageField}
          </>
        </Paper>
      )}

      {field.fieldName !== "names_separated_furigana" &&
      field.fieldName !== "credit_card" &&
      field.fieldName !== "names_furigana" ? (
        Object.keys(fieldErrors).map((key) =>
          key == field.id && fieldErrors[key] ? (
            <Tooltip
              key={key}
              anchorSelect={`#${field.id}`}
              openOnClick
              place="top-end"
              offset={5}
              isOpen={fieldErrors[key]}
              afterHide={() => {
                // setFieldErrors((prev) => ({ ...prev, [key]: false }));
                handleChangeFieldErrors(key, false);
              }}
              style={{
                backgroundColor: "#f2850c",
                color: "#fff",
                fontSize: 12,
                zIndex: 1,
                transform: "translateX(-6px)",
                paddingTop: 3,
                paddingLeft: 3,
                paddingRight: 3,
                paddingBottom: 5,
              }}
              opacity={0.95}
            >
              <div>
                {errorMessages[key].map((msg: any, idx: any) => (
                  <div key={idx}>*{msg}</div>
                ))}
              </div>
            </Tooltip>
          ) : null
        )
      ) : field.fieldName == "names_separated_furigana" ? (
        <>
          {fieldErrors[`familyNameKanji-${field.id}`] && (
            <Tooltip
              anchorSelect={`#familyNameKanji-${field.id}`}
              openOnClick
              place="top-end"
              offset={5}
              isOpen={fieldErrors[`familyNameKanji-${field.id}`]}
              afterHide={() => {
                handleChangeFieldErrors(`familyNameKanji-${field.id}`, false);
              }}
              style={{
                backgroundColor: "#f2850c",
                color: "#fff",
                fontSize: 12,
                zIndex: 1,
                transform: "translateX(5px)",
                paddingTop: 3,
                paddingLeft: 3,
                paddingRight: 3,
                paddingBottom: 5,
              }}
              opacity={0.95}
            >
              <div>
                {errorMessages[`familyNameKanji-${field.id}`].map(
                  (msg: any, idx: any) => (
                    <div key={idx}>*{msg}</div>
                  )
                )}
              </div>
            </Tooltip>
          )}
          {fieldErrors[`givenNameKanji-${field.id}`] && (
            <Tooltip
              anchorSelect={`#givenNameKanji-${field.id}`}
              openOnClick
              place="top-end"
              offset={5}
              isOpen={fieldErrors[`givenNameKanji-${field.id}`]}
              afterHide={() => {
                handleChangeFieldErrors(`givenNameKanji-${field.id}`, false);
              }}
              style={{
                backgroundColor: "#f2850c",
                color: "#fff",
                fontSize: 12,
                zIndex: 1,
                transform: "translateX(5px)",
                paddingTop: 3,
                paddingLeft: 3,
                paddingRight: 3,
                paddingBottom: 5,
              }}
              opacity={0.95}
            >
              <div>
                {errorMessages[`givenNameKanji-${field.id}`].map(
                  (msg: any, idx: any) => (
                    <div key={idx}>*{msg}</div>
                  )
                )}
              </div>
            </Tooltip>
          )}
          {fieldErrors[`familyNameKana-${field.id}`] && (
            <Tooltip
              anchorSelect={`#familyNameKana-${field.id}`}
              openOnClick
              place="top-end"
              offset={5}
              isOpen={fieldErrors[`familyNameKana-${field.id}`]}
              afterHide={() => {
                handleChangeFieldErrors(`familyNameKana-${field.id}`, false);
              }}
              style={{
                backgroundColor: "#f2850c",
                color: "#fff",
                fontSize: 12,
                zIndex: 1,
                transform: "translateX(5px)",
                paddingTop: 3,
                paddingLeft: 3,
                paddingRight: 3,
                paddingBottom: 5,
              }}
              opacity={0.95}
            >
              <div>
                {errorMessages[`familyNameKana-${field.id}`].map(
                  (msg: any, idx: any) => (
                    <div key={idx}>*{msg}</div>
                  )
                )}
              </div>
            </Tooltip>
          )}
          {fieldErrors[`givenNameKana-${field.id}`] && (
            <Tooltip
              anchorSelect={`#givenNameKana-${field.id}`}
              openOnClick
              place="top-end"
              offset={5}
              isOpen={fieldErrors[`givenNameKana-${field.id}`]}
              afterHide={() => {
                handleChangeFieldErrors(`givenNameKana-${field.id}`, false);
              }}
              style={{
                backgroundColor: "#f2850c",
                color: "#fff",
                fontSize: 12,
                zIndex: 1,
                transform: "translateX(5px)",
                paddingTop: 3,
                paddingLeft: 3,
                paddingRight: 3,
                paddingBottom: 5,
              }}
              opacity={0.95}
            >
              <div>
                {errorMessages[`givenNameKana-${field.id}`].map(
                  (msg: any, idx: any) => (
                    <div key={idx}>*{msg}</div>
                  )
                )}
              </div>
            </Tooltip>
          )}
        </>
      ) : field.fieldName == "credit_card" ? (
        <>
          {fieldErrors[`creditCardNumber-${field.id}`] && (
            <Tooltip
              anchorSelect={`#creditCardNumber-${field.id}`}
              openOnClick
              place="top-end"
              offset={5}
              isOpen={fieldErrors[`creditCardNumber-${field.id}`]}
              afterHide={() => {
                handleChangeFieldErrors(`creditCardNumber-${field.id}`, false);
              }}
              style={{
                backgroundColor: "#f2850c",
                color: "#fff",
                fontSize: 12,
                zIndex: 1,
                transform: "translateX(5px)",
                paddingTop: 3,
                paddingLeft: 3,
                paddingRight: 3,
                paddingBottom: 5,
              }}
              opacity={0.95}
            >
              <div>
                {errorMessages[`creditCardNumber-${field.id}`].map(
                  (msg: any, idx: any) => (
                    <div key={idx}>*{msg}</div>
                  )
                )}
              </div>
            </Tooltip>
          )}
          {fieldErrors[`creditCardHolder-${field.id}`] && (
            <Tooltip
              anchorSelect={`#creditCardHolder-${field.id}`}
              openOnClick
              place="top-end"
              offset={5}
              isOpen={fieldErrors[`creditCardHolder-${field.id}`]}
              afterHide={() => {
                handleChangeFieldErrors(`creditCardHolder-${field.id}`, false);
              }}
              style={{
                backgroundColor: "#f2850c",
                color: "#fff",
                fontSize: 12,
                zIndex: 1,
                transform: "translateX(5px)",
                paddingTop: 3,
                paddingLeft: 3,
                paddingRight: 3,
                paddingBottom: 5,
              }}
              opacity={0.95}
            >
              <div>
                {errorMessages[`creditCardHolder-${field.id}`].map(
                  (msg: any, idx: any) => (
                    <div key={idx}>*{msg}</div>
                  )
                )}
              </div>
            </Tooltip>
          )}
          {fieldErrors[`creditCardSecurityCode-${field.id}`] && (
            <Tooltip
              anchorSelect={`#creditCardSecurityCode-${field.id}`}
              openOnClick
              place="top-end"
              offset={5}
              isOpen={fieldErrors[`creditCardSecurityCode-${field.id}`]}
              afterHide={() => {
                handleChangeFieldErrors(
                  `creditCardSecurityCode-${field.id}`,
                  false
                );
              }}
              style={{
                backgroundColor: "#f2850c",
                color: "#fff",
                fontSize: 12,
                zIndex: 1,
                transform: "translateX(5px)",
                paddingTop: 3,
                paddingLeft: 3,
                paddingRight: 3,
                paddingBottom: 5,
              }}
              opacity={0.95}
            >
              <div>
                {errorMessages[`creditCardSecurityCode-${field.id}`].map(
                  (msg: any, idx: any) => (
                    <div key={idx}>*{msg}</div>
                  )
                )}
              </div>
            </Tooltip>
          )}
        </>
      ) : field.fieldName == "names_furigana" ? (
        <>
          {fieldErrors[`familyNameKana-${field.id}`] && (
            <Tooltip
              anchorSelect={`#familyNameKana-${field.id}`}
              openOnClick
              place="top-end"
              offset={5}
              isOpen={fieldErrors[`familyNameKana-${field.id}`]}
              afterHide={() => {
                handleChangeFieldErrors(`familyNameKana-${field.id}`, false);
              }}
              style={{
                backgroundColor: "#f2850c",
                color: "#fff",
                fontSize: 12,
                zIndex: 1,
                transform: "translateX(5px)",
                paddingTop: 3,
                paddingLeft: 3,
                paddingRight: 3,
                paddingBottom: 5,
              }}
              opacity={0.95}
            >
              <div>
                {errorMessages[`familyNameKana-${field.id}`].map(
                  (msg: any, idx: any) => (
                    <div key={idx}>*{msg}</div>
                  )
                )}
              </div>
            </Tooltip>
          )}
          {fieldErrors[`givenNameKana-${field.id}`] && (
            <Tooltip
              anchorSelect={`#givenNameKana-${field.id}`}
              openOnClick
              place="top-end"
              offset={5}
              isOpen={fieldErrors[`givenNameKana-${field.id}`]}
              afterHide={() => {
                handleChangeFieldErrors(`givenNameKana-${field.id}`, false);
              }}
              style={{
                backgroundColor: "#f2850c",
                color: "#fff",
                fontSize: 12,
                zIndex: 1,
                transform: "translateX(5px)",
                paddingTop: 3,
                paddingLeft: 3,
                paddingRight: 3,
                paddingBottom: 5,
              }}
              opacity={0.95}
            >
              <div>
                {errorMessages[`givenNameKana-${field.id}`].map(
                  (msg: any, idx: any) => (
                    <div key={idx}>*{msg}</div>
                  )
                )}
              </div>
            </Tooltip>
          )}
        </>
      ) : (
        <></>
      )}

      {field.isTooltip &&
        field.toolTipText !== "" &&
        !Object.keys(fieldErrors).some((key) => fieldErrors[key]) && (
          <Tooltip
            anchorSelect={`#${field.id}`}
            content={field.toolTipText}
            openOnClick
            place="top-end"
            offset={5}
            isOpen={isHelperTooltipOpen}
            afterHide={() => {
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
              }
            }}
            style={{
              backgroundColor: "#7db756",
              fontSize: 12,
              color: "#fff",
              zIndex: 1,
              transform: "translateX(-6px)",
              paddingTop: 3,
              paddingLeft: 3,
              paddingRight: 3,
              paddingBottom: 5,
            }}
            opacity={0.95}
          />
        )}
    </>
  );
}
