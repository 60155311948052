import { useEffect, useRef, useState } from "react";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { useParams } from "src/hooks/useParams";
import {
  videoChooseProps,
  nextActionsType,
  videoType,
  videosType,
  IVideoChooseForm,
  FormFields,
} from "src/types/video";

import LinearProgress from "@mui/material/LinearProgress";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Iconify from "src/components/Iconify";
import { useSnackbar } from "notistack";
import { useResponsive } from "src/hooks/useResponsive";
import uuidv4 from "src/utils/uuidv4";
import Modal from "@mui/material/Modal";
import VideoChooseForm from "./VideoChoosePreviewComponent/VideoChooseForm";
import Fade from "@mui/material/Fade";

interface MessageData {
  action: "play" | "pause" | "openIFrame" | "openForm";
  form_id?: string;
}

const sendMessage = (
  anchor: string | undefined,
  is_close_anchor_click: boolean | undefined
) => {
  window.parent.postMessage(
    {
      action: "navigate",
      anchor: `#${anchor}`,
      close: is_close_anchor_click,
    },
    "*"
  );
};

const sendCostomScriptIdMessage = (scriptId: string | undefined) => {
  window.parent.postMessage(
    {
      action: "customScript",
      scriptId: scriptId,
    },
    "*"
  );
};

interface SubtitleProps {
  subtitleUrl: string | null;
  currentTime: number;
  isOptions: boolean;
  opacity: number;
  subtitleTextColor: string | null;
  subtitleBackgroundColor: string | null;
  subtitleFontSize: number;
}

interface AnnotationProps {
  annotationUrl: string | null;
  currentTime: number;
  annotationFontSize: number;
}

const parseTimestamp = (timestamp: string) => {
  const [hours, minutes, seconds] = timestamp.split(":").map(parseFloat);
  return hours * 3600 + minutes * 60 + seconds;
};

function getJSTDate() {
  const now = new Date();
  const utc = now.getTime() + now.getTimezoneOffset() * 60000;
  const jst = new Date(utc + 9 * 60 * 60 * 1000);

  const year = jst.getFullYear();
  const month = String(jst.getMonth() + 1).padStart(2, "0");
  const day = String(jst.getDate()).padStart(2, "0");
  const hours = String(jst.getHours()).padStart(2, "0");
  const minutes = String(jst.getMinutes()).padStart(2, "0");
  const seconds = String(jst.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+09:00`;
}

const Subtitle: React.FC<SubtitleProps> = ({
  subtitleUrl,
  currentTime,
  isOptions,
  opacity,
  subtitleTextColor,
  subtitleBackgroundColor,
  subtitleFontSize,
}) => {
  const [subtitles, setSubtitles] = useState<
    { start: number; end: number; text: string }[]
  >([]);

  useEffect(() => {
    const fetchSubtitles = async () => {
      if (subtitleUrl) {
        const response = await fetch(subtitleUrl);
        const data = await response.json();

        // データが配列であることを確認
        if (Array.isArray(data)) {
          // 配列の各要素が連想配列であることを確認
          const isAssociativeArray = data.every(
            (item) => typeof item === "object" && item !== null
          );

          if (isAssociativeArray) {
            // データを適切な型に変換
            const parsedSubtitles = data.map((item) => ({
              start: parseTimestamp(item.start),
              end: parseTimestamp(item.end),
              text: item.text,
            }));

            setSubtitles(parsedSubtitles);
          } else {
            console.log(
              "The elements of the data array are not associative arrays"
            );
          }
        } else {
          console.log("The data is not an array");
        }
      } else {
        setSubtitles([]);
      }
    };

    fetchSubtitles();
  }, [subtitleUrl]);

  const currentSubtitle = subtitles.find(
    (subtitle) => currentTime >= subtitle.start && currentTime <= subtitle.end
  );

  const renderStyledText = (text: string) => {
    const parts = text.split(/(\*\d+)/);
    return parts.map((part, index) => {
      if (part.startsWith("*") && /^\*\d+$/.test(part)) {
        return (
          <span
            key={index}
            style={{ fontSize: "0.7em", verticalAlign: "super" }}
          >
            ※{part.slice(1)}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <div
      style={{
        position: isOptions ? "static" : "absolute",
        minWidth: "50%",
        textAlign: "center",
        zIndex: 300,
        opacity: isOptions ? 1 - opacity : opacity,
        width: "100%",
        maxWidth: 320,
        ...(isOptions && {
          margin: "10px auto",
        }),
        ...(!isOptions && {
          bottom: "120px",
          left: "50%",
          transform: "translateX(-50%)",
        }),
        ...(subtitleFontSize >= 20 && {
          minHeight: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }),
      }}
    >
      {currentSubtitle ? (
        <div
          style={{
            backgroundColor: subtitleBackgroundColor || "rgba(20, 20, 20, 0.5)",
            color: subtitleTextColor || "white",
            padding: "5px 10px",
            borderRadius: "5px",
            fontSize: subtitleFontSize,
            minHeight: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: 320,
            margin: "0 auto",
            ...(subtitleFontSize >= 20 && {
              width: "100%",
            }),
          }}
        >
          <span
          // style={{ fontFamily: '"Open Sans", sans-serif' }}
          >
            {/* {currentSubtitle.text} */}
            {renderStyledText(currentSubtitle.text)}
          </span>
        </div>
      ) : (
        subtitleUrl && (
          <div
            style={{
              backgroundColor: "transparent",
              padding: "5px 10px",
              minHeight: 30,
              maxWidth: 320,
              margin: "0 auto",
            }}
          ></div>
        )
      )}
    </div>
  );
};

const Annotation: React.FC<AnnotationProps> = ({
  annotationUrl,
  currentTime,
  annotationFontSize,
}) => {
  const [annotations, setAnnotations] = useState<
    { start: number; end: number; text: string }[]
  >([]);

  useEffect(() => {
    const fetchAnnotations = async () => {
      if (annotationUrl) {
        const response = await fetch(annotationUrl);
        const data = await response.json();

        // データが配列であることを確認
        if (Array.isArray(data)) {
          // 配列の各要素が連想配列であることを確認
          const isAssociativeArray = data.every(
            (item) => typeof item === "object" && item !== null
          );

          if (isAssociativeArray) {
            // データを適切な型に変換
            const parsedAnnotations = data.map((item) => ({
              start: parseTimestamp(item.start),
              end: parseTimestamp(item.end),
              text: item.text,
            }));

            setAnnotations(parsedAnnotations);
          } else {
            console.log(
              "The elements of the data array are not associative arrays"
            );
          }
        } else {
          console.log("The data is not an array");
        }
      } else {
        setAnnotations([]);
      }
    };

    fetchAnnotations();
  }, [annotationUrl]);

  const currentSubtitle = annotations.find(
    (annotation) =>
      currentTime >= annotation.start && currentTime <= annotation.end
  );

  return (
    <>
      {currentSubtitle ? (
        <div
          style={{
            color: "#000",
            padding: "5px 10px",
            borderRadius: "5px",
            fontSize: annotationFontSize,
            minHeight: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: 320,
            margin: "0 auto",
          }}
        >
          <span>{currentSubtitle.text}</span>
        </div>
      ) : (
        annotationUrl && (
          <div
            style={{
              backgroundColor: "transparent",
              padding: "5px 10px",
              minHeight: 30,
              maxWidth: 320,
              margin: "0 auto",
            }}
          ></div>
        )
      )}
    </>
  );
};

export default function VideoChoose() {
  //動画タグのref
  const videoRef = useRef<HTMLVideoElement>(null);
  const mdUp = useResponsive("up", "md");
  const iframeXSdown = useResponsive("down", "iframeXS");

  //選択肢のボックスのref
  const boxRef = useRef<HTMLDivElement>(null);

  //URLからvideoChooseIdを取得
  const params = useParams();
  const { videoChooseId } = params;
  // const isIFrame = window.self !== window.top || window.frameElement !== null;
  const searchParams = new URLSearchParams(window.location.search);
  const isIFrame = searchParams.get("iframe") === "true";
  const isClose = searchParams.get("close") === "true";
  const isFirstAutoPlay = searchParams.get("autoplay") === "true";
  const [firstAutoPlay, setFirstAutoPlay] = useState<boolean>(isFirstAutoPlay);

  //videoChooseインスタンス
  const [videoChoose, setVideoChoose] = useState<videoChooseProps>({
    videos: {},
    nextActions: {},
    videosOrdered: [],
    formGroups: {},
  });
  const [opacity, setOpacity] = useState(1); // 透明度の状態

  //再生されるvideo
  const [video, setVideo] = useState<videoType | null>(null);

  //ProgressBarの値
  const [progress, setProgress] = useState<number>(0);

  //ローディング状態
  const [loading, setLoading] = useState<boolean>(true);

  //Videoローディング状態
  const [videoLoading, setVideoLoading] = useState<boolean>(false);

  //選択肢のトグルボタンの表示
  const [isShowButton, setIsShowButton] = useState<boolean>(false);

  //選択ボタンの透明度
  const [buttonOpacity, setButtonOpacity] = useState(1);

  //再生ボタンの透明度
  const [playButtonOpacity, setPlayButtonOpacity] = useState(1);

  //動画の終了状況の確認
  const [isVideoEnd, setIsVideoEnd] = useState(false);

  //動画再生時に選択肢を自動で閉じるかどうか
  // const [autoClose, setAutoClose] = useState<boolean>(false);

  //ProgressBarのアニメーション
  const [progressTransition, setProgressTransition] = useState("0.3s linear");

  //自動再生(制御に使用するのみ、最初の動画は再生せず、選択肢を押した後の動画は自動再生できるようにする)
  const [autoPlay, setAutoPlay] = useState<boolean>(false);

  //動画のミュート
  const [isMuted, setIsMuted] = useState<boolean>(
    isFirstAutoPlay ? true : false
  );

  //動画再生履歴
  const [history, setHistory] = useState<string[]>([]);

  //共有リンク
  const [shareLink, setShareLink] = useState<boolean>(false);

  const [forms, setForms] = useState<IVideoChooseForm>({});

  // フォームの入力値を保存する
  const [savedValues, setSavedValues] = useState<{ [key: string]: any }>({});

  const [formModal, setFormModal] = useState<boolean>(false);
  const [selectedFormId, setSelectedFormId] = useState<string>("");
  const [selectedFormStep, setSelectedFormStep] = useState<number>(0);
  const [filledFormStep, setFilledFormStep] = useState<number>(0);

  const [playButtonClass, setPlayButtonClass] = useState(
    "video-choose-play-button"
  );
  const [audioPulseClass, setAudioPulseClass] = useState<string>("");

  const [vcReadyPostMessage, setVcReadyPostMessage] = useState<boolean>(false);

  // let autoCloseVideos: string[] = [];
  const [autoCloseVideos, setAutoCloseVideos] = useState<string[]>([]);

  const [hideCopyright, setHideCopyright] = useState<boolean>(false);

  const [confirmation, setConfirmation] = useState<boolean>(false);

  const [domain, setDomain] = useState<string | null>(null);

  const handleChangeConfirmation = () => {
    setConfirmation((prev) => !prev);
  };

  let consumerToken = localStorage.getItem("consumerToken") || uuidv4();

  if (!localStorage.getItem("consumerToken")) {
    localStorage.setItem("consumerToken", consumerToken);
  }

  // visitTokenの生成
  const [visitToken, setVisitToken] = useState(uuidv4());

  const [isParentUrlValid, setIsParentUrlValid] = useState(false);

  //通知バー
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const handleTouchMove = (event: TouchEvent) => {
      // event.targetがテキストエリアのrefであるかを確認
      if (boxRef.current && boxRef.current.contains(event.target as Node)) {
        // テキストエリア内でのイベントは通常通り処理（ここでは何もしない）
      } else {
        // テキストエリア外でのスクロールを防ぐ
        event.preventDefault();
      }
    };

    // touchmoveイベントでページ全体のスクロールを制御
    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    // コンポーネントがアンマウントされる時にイベントリスナーをクリーンアップ
    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  //選択肢がスクロールされると透明度を変化する
  const handleScroll = (event: React.UIEvent<Element>) => {
    const target = event.target as HTMLElement;
    const scrollY = target.scrollTop;

    const opacity = Math.max(1 - scrollY / 50, 0);

    const toggleOpacity = 1 - Math.max(1 - scrollY / 50, 0);

    // boxRefの透明度を更新
    if (boxRef.current) {
      boxRef.current.style.opacity = toggleOpacity.toString();
    }

    setButtonOpacity(opacity);
    if (opacity == 0) {
      setIsShowButton(false);
    } else {
      setIsShowButton(true);
    }
  };

  //選択肢の矢印ボタンを押したら選択肢を画面上に表示させる
  const handleScrollToTop = () => {
    const start = boxRef.current ? boxRef.current.scrollTop : 0;
    const change = 400 - start; // スクロールする距離
    const duration = 400; // アニメーション時間を400ミリ秒に設定
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutCubic(currentTime, start, change, duration);
      const opacity = easeInOutCubic(currentTime, 0.5, 1, duration); // 透明度を0から1へ変化させる

      if (boxRef.current) {
        boxRef.current.scrollTop = val;
        boxRef.current.style.opacity = opacity.toString(); // 透明度を適用
      }

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    const easeInOutCubic = (t: number, b: number, c: number, d: number) => {
      t /= d;
      t--;
      return c * (t * t * t + 1) + b;
    };

    animateScroll();
  };

  //再生を押したら選択肢を画面下に隠す
  const handleScrollToBottom = () => {
    const start = boxRef.current ? boxRef.current.scrollTop : 0;
    let change = -400;
    if (video?.nextActions && video?.nextActions.length !== 0) {
      change = -(video?.nextActions.length * 100); // スクロールする距離を逆にする
    }
    const duration = 600; // アニメーション時間は400ミリ秒
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutCubic(currentTime, start, change, duration);
      const opacity = 1 - easeInOutCubic(currentTime, 0, 0.5, duration); // 透明度を1から0へ変化させる

      if (boxRef.current) {
        boxRef.current.scrollTop = val;
        boxRef.current.style.opacity = opacity.toString(); // 透明度を適用
      }

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    const easeInOutCubic = (t: number, b: number, c: number, d: number) => {
      t /= d;
      t--;
      return c * (t * t * t + 1) + b;
    };

    animateScroll();
  };

  //最初に動画の選択肢を表示させる
  useEffect(() => {
    const start = boxRef.current ? boxRef.current.scrollTop : 0;
    const change = 400 - start;
    const duration = 1;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutCubic(currentTime, start, change, duration);
      const opacity = easeInOutCubic(currentTime, 0.5, 1, duration);

      if (boxRef.current) {
        boxRef.current.scrollTop = val;
        boxRef.current.style.opacity = opacity.toString();
      }

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    const easeInOutCubic = (t: number, b: number, c: number, d: number) => {
      t /= d;
      t--;
      return c * (t * t * t + 1) + b;
    };

    if (boxRef.current && video) {
      animateScroll();
    }
  }, [boxRef.current, video]);

  useEffect(() => {
    const checkTime = () => {
      if (
        videoRef.current &&
        video &&
        video.open_option_time &&
        videoRef.current.currentTime >= video.open_option_time
      ) {
        handleScrollToTop();
        videoRef.current.removeEventListener("timeupdate", checkTime);
      }
    };

    if (videoRef.current && video && video.open_option_time) {
      videoRef.current.addEventListener("timeupdate", checkTime);
    }

    return () => {
      if (videoRef.current && video && video.open_option_time) {
        videoRef.current.removeEventListener("timeupdate", checkTime);
      }
    };
  }, [video]);
  //progressの値を更新する関数
  //imageのカバーを透明にする
  const handleTimeUpdate = () => {
    const videoEl = videoRef.current;
    if (videoEl && videoEl.currentTime > 0) {
      setVideoLoading(false);
      setOpacity(0);
    }
    if (videoEl) {
      const currentProgress = (videoEl.currentTime / videoEl.duration) * 100;
      setProgress(currentProgress);
    }
  };

  const handleFormStep = (formIndex: number, formId: string) => {
    if (forms[formId].form_group) {
      setSelectedFormStep(formIndex);

      setSelectedFormId(
        // @ts-ignore: Suppress TypeScript error for indexing with potentially undefined key
        videoChoose.formGroups[forms[formId].form_group].formOrder[formIndex]
          .formId
      );

      if (forms[formId].action_type === "form") {
        setFilledFormStep(formIndex);
      }
    }
  };

  const handleFormStepProgressBar = (formIndex: number, formId: string) => {
    if (forms[formId].form_group) {
      setSelectedFormStep(formIndex);

      setSelectedFormId(
        // @ts-ignore: Suppress TypeScript error for indexing with potentially undefined key
        videoChoose.formGroups[forms[formId].form_group].formOrder[formIndex]
          .formId
      );
    }
  };

  //再生中にprogressの値を更新する関数を実行する
  useEffect(() => {
    const videoEl = videoRef.current;
    if (videoEl) {
      videoEl.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        videoEl.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [video, videoChoose]);

  /*
  動画が終了した時に、ProgressBarのアニメーションを停止する
  isVideoEndがtrueになると、巻き戻しボタンが表示される
  */
  useEffect(() => {
    const videoEl = videoRef.current;

    if (videoEl) {
      const handleVideoEnd = (): void => {
        setIsVideoEnd(true);
        setProgressTransition("none");
        if (video && video.is_auto_form_at_end) {
          if (video.form_id) {
            setSelectedFormId(video.form_id);

            if (forms[video.form_id] && forms[video.form_id].form_group) {
              const formStep = videoChoose.formGroups[
                // @ts-ignore: Suppress TypeScript error for indexing with potentially undefined key
                forms[video.form_id].form_group
              ].formOrder.findIndex(
                (form: any) => form.formId === video.form_id
              );

              setSelectedFormStep(formStep);
              setFilledFormStep(formStep);
            }

            setFormModal(true);
          }
        }
        handleScrollToTop();
      };

      videoEl.addEventListener("ended", handleVideoEnd);

      return () => {
        videoEl.removeEventListener("ended", handleVideoEnd);
      };
    }
  }, [video, videoChoose, progressTransition]);

  //巻き戻しボタンが実行された時に、ProgressBarのアニメーションを再開する
  useEffect(() => {
    if (progress === 0) {
      setProgressTransition("0.3s linear");
    }
  }, [progress]);

  //videoChooseIdから特定のvideoChooseを取得する
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (videoChooseId) {
          const db = getFirestore();
          const docRef = doc(db, "videoChooses", videoChooseId);
          const docSnap = await getDoc(docRef);

          let videos: videosType = {};
          let nextActions: nextActionsType = {};
          let videosOrdered: string[] = [];
          let formGroups: any = {};
          if (docSnap.exists()) {
            const videosCollectionRef = collection(docRef, "videos");
            const videosSnapshot = await getDocs(videosCollectionRef);
            const videosAll = await Promise.all(
              videosSnapshot.docs.map(async (videoDoc) => {
                videos[videoDoc.id] = {
                  id: videoDoc.id,
                  nextActions: videoDoc.data().order,
                  video_url: videoDoc.data().video_url,
                  video_title: videoDoc.data().video_title,
                  thumbnail: videoDoc.data().thumbnail,
                  subtitle_url: videoDoc.data().subtitle_url || null,
                  subtitle_text_color:
                    videoDoc.data().subtitle_text_color || null,
                  subtitle_background_color:
                    videoDoc.data().subtitle_background_color || null,
                  annotation_url: videoDoc.data().annotation_url || null,
                  is_auto_form_at_end:
                    videoDoc.data().is_auto_form_at_end || false,
                  form_id: videoDoc.data().form_id || null,
                  open_option_time: videoDoc.data().open_option_time || null,
                  title_color: videoDoc.data().title_color || null,
                  back_button_color: videoDoc.data().back_button_color || null,
                  subtitle_font_size: videoDoc.data().subtitle_font_size || 13,
                  annotation_font_size:
                    videoDoc.data().annotation_font_size || 6.5,
                };

                if (videoDoc.data().auto_close_options) {
                  setAutoCloseVideos((prev) => {
                    return [...prev, videoDoc.id];
                  });
                }

                //サムネイルのみ先読み込み
                const img = new Image();
                img.src = videoDoc.data().thumbnail;

                const videoData = videoDoc.data();

                const nextActionsCollectionRef = collection(
                  videoDoc.ref,
                  "nextActions"
                );

                const nextActionsSnapshot = await getDocs(
                  nextActionsCollectionRef
                );
                nextActionsSnapshot.docs.forEach((doc) => {
                  nextActions[doc.id] = { id: doc.id, ...doc.data() };
                });

                return { video_id: videoDoc.id, ...videoData, nextActions };
              })
            );
            videosOrdered = docSnap.data().order;
            formGroups = docSnap.data().form_groups;
            setIsMuted(docSnap.data()?.default_muted || false);
            if (!!docSnap.data()?.default_muted) {
              setAudioPulseClass("video-choose-audio-pulse-button");
            }
            setShareLink(docSnap.data()?.link_option || false);
            setHideCopyright(docSnap.data()?.hide_copyright);
            if (docSnap.exists()) {
              setDomain(docSnap.data().domain || null);
            } else {
              setDomain(null);
            }
            setVideoChoose({
              videos,
              nextActions,
              videosOrdered,
              formGroups,
            });
          } else {
            console.log("No such document!");
          }
        }
      } catch (error) {
        if (error.code === "permission-denied") {
          console.log(
            "Access denied: You don't have permission to access this data."
          );
        } else {
          console.error("Error fetching data:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [videoChooseId]);

  //videoChooseIdから特定のvideoChooseを取得する
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (videoChooseId) {
          const db = getFirestore();
          const docRef = doc(db, "videoChooses", videoChooseId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const formsCollectionRef = collection(docRef, "forms");
            const formsSnapshot = await getDocs(formsCollectionRef);

            formsSnapshot.docs.map(async (formDoc) => {
              const fieldsCollectionRef = collection(formDoc.ref, "fields");
              const fieldsSnapshot = await getDocs(fieldsCollectionRef);
              const fields: FormFields = fieldsSnapshot.docs.reduce(
                (acc, fieldDoc) => {
                  const field = {
                    id: fieldDoc.id,
                    type: fieldDoc.data().type,
                    fieldName: fieldDoc.data().fieldName,
                    label: fieldDoc.data().label,
                    required: fieldDoc.data().required,
                    ...(fieldDoc.data().options
                      ? { options: fieldDoc.data().options }
                      : {}),
                    ...(fieldDoc.data().radioOptions
                      ? { radioOptions: fieldDoc.data().radioOptions }
                      : {}),
                    ...(fieldDoc.data().defaultValue
                      ? { defaultValue: fieldDoc.data().defaultValue }
                      : {}),
                    ...(fieldDoc.data().fieldCustomId
                      ? { fieldCustomId: fieldDoc.data().fieldCustomId }
                      : {}),

                    ...(fieldDoc.data().isConditionalDisplay !== undefined
                      ? {
                          isConditionalDisplay:
                            fieldDoc.data().isConditionalDisplay,
                          conditionField: fieldDoc.data().conditionField,
                          conditionFieldValues:
                            fieldDoc.data().conditionFieldValues,
                        }
                      : {}),
                    ...(fieldDoc.data().isTooltip !== undefined
                      ? {
                          isTooltip: fieldDoc.data().isTooltip,
                          toolTipText: fieldDoc.data().toolTipText,
                        }
                      : {}),
                    ...(fieldDoc.data().selectMultiple !== undefined
                      ? {
                          selectMultiple: fieldDoc.data().selectMultiple,
                        }
                      : {}),
                    ...(fieldDoc.data().checkboxDescription
                      ? {
                          checkboxDescription:
                            fieldDoc.data().checkboxDescription,
                        }
                      : {}),
                    ...(fieldDoc.data().imageUrl
                      ? {
                          imageUrl: fieldDoc.data().imageUrl,
                        }
                      : {}),
                    ...(fieldDoc.data().minDateType !== undefined
                      ? {
                          minDateType: fieldDoc.data().minDateType,
                          minDateDaysAfter: fieldDoc.data().minDateDaysAfter,
                          minSelectedDate: fieldDoc.data().minSelectedDate,
                        }
                      : {}),
                    ...(fieldDoc.data().maxDateType !== undefined
                      ? {
                          maxDateType: fieldDoc.data().maxDateType,
                          maxDateDaysAfter: fieldDoc.data().maxDateDaysAfter,
                          maxSelectedDate: fieldDoc.data().maxSelectedDate,
                        }
                      : {}),
                  };
                  return { ...acc, [fieldDoc.id]: field };
                },
                {}
              );

              const form: IVideoChooseForm = {
                [formDoc.id]: {
                  title: formDoc.data().title,
                  button_text: formDoc.data().button_text,
                  description: formDoc.data().description,
                  fields: fields,
                  send_color: formDoc.data().send_color,
                  order: formDoc.data().order,
                  is_title_displayed: formDoc.data().is_title_displayed,
                  send_type: formDoc.data().send_type
                    ? formDoc.data().send_type
                    : "submit",
                  video_id: formDoc.data().video_id
                    ? formDoc.data().video_id
                    : null,
                  complete_submit_title: formDoc.data().complete_submit_title,
                  complete_submit_description:
                    formDoc.data().complete_submit_description,
                  custom_script_id: formDoc.data().custom_script_id,
                  is_action_with_custom_script:
                    formDoc.data().is_action_with_custom_script,
                  action_type: formDoc.data().action_type,
                  is_waiting_validation: formDoc.data().is_waiting_validation
                    ? true
                    : false,
                  form_group: formDoc.data().form_group,
                  is_custom_script_after_submission: formDoc.data()
                    .is_custom_script_after_submission
                    ? true
                    : false,
                  custom_script_id_after_submission:
                    formDoc.data().custom_script_id_after_submission,
                  is_form_sync_with_submit: formDoc.data()
                    .is_form_sync_with_submit
                    ? true
                    : false,
                  conditions: formDoc.data().conditions,
                },
              };

              setForms((prevForms) => {
                return { ...prevForms, ...form };
              });
            });
          } else {
            console.log("No such document!");
          }
        }
      } catch (error) {
        if (error.code === "permission-denied") {
          console.log(
            "Access denied: You don't have permission to access this data."
          );
        } else {
          console.error("Error fetching data:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [videoChooseId]);

  /*
  videoChooseが変更された時に、最初のvideoをセットする
  さらに、historyに最初のvideoを追加する
  */
  useEffect(() => {
    setVideo(videoChoose.videos[videoChoose.videosOrdered[0]]);
    if (videoChoose.videosOrdered[0]) {
      setHistory((prevHistory) => [
        ...prevHistory,
        videoChoose.videosOrdered[0],
      ]);
    }
  }, [videoChoose]);

  /*
  videoに変更があった時に再生を開始する。
  ただし、autoPlayがfalse(初期状態)の時は再生しない
  ProgressBarのアニメーションを再開する
  */
  useEffect(() => {
    if (video && videoRef.current && autoPlay) {
      videoRef.current.play();
      setProgressTransition("0.3s linear");
      // if (autoClose) {
      //   handleScrollToBottom();
      // }
      if (autoCloseVideos.includes(video.id)) {
        handleScrollToBottom();
      }
    }
  }, [video]);

  //iframeで実行された場合
  useEffect(() => {
    const handleReceiveMessage = (event: MessageEvent<MessageData>) => {
      if (
        (typeof event.data !== "object" ||
          event.data === null ||
          !event.data.action) &&
        consumerToken &&
        visitToken &&
        videoChooseId &&
        domain !== undefined
      ) {
        return;
      }

      const data = event.data;

      switch (data.action) {
        case "play":
          videoRef.current?.play();
          fadeOutButton();
          if (video && autoCloseVideos.includes(video.id)) {
            handleScrollToBottom();
            setIsShowButton(true);
          } else {
            handleScrollToTop();
          }
          setPlayButtonClass("");
          setTimeout(() => {
            setAudioPulseClass("");
          }, 7000);

          if (process.env.REACT_APP_PROD && videoChooseId) {
            window.parent.postMessage(
              {
                action: "setVcCvCookie",
                consumerToken: consumerToken,
                visitToken: visitToken,
                videoChooseId: videoChooseId,
                viewedAt: getJSTDate(),
                measurementTag: "via-video-only",
                domain: domain,
              },
              "*"
            );
          }

          break;
        case "pause":
          videoRef.current?.pause();
          break;
        case "openIFrame":
          if (video && autoCloseVideos.includes(video.id)) {
            handleScrollToBottom();
            setIsShowButton(true);
          } else {
            handleScrollToTop();
          }
          break;
        case "openForm":
          if (data.form_id) {
            setSelectedFormId(data.form_id);

            if (
              forms[data.form_id] &&
              forms[data.form_id]?.form_group !== undefined
            ) {
              const formGroup = forms[data.form_id].form_group;
              if (
                formGroup !== undefined &&
                videoChoose.formGroups[formGroup]
              ) {
                const formStep = videoChoose.formGroups[
                  formGroup
                ].formOrder.findIndex(
                  (form: any) => form.formId === data.form_id
                );
                setSelectedFormStep(formStep);
                setFilledFormStep(formStep);
              }
            }

            window.parent.postMessage(
              {
                action: "setVcCvCookie",
                consumerToken: consumerToken,
                visitToken: visitToken,
                videoChooseId: videoChooseId,
                viewedAt: getJSTDate(),
                measurementTag: "via-form-only",
                domain: domain,
              },
              "*"
            );

            setFormModal(true);
          }
          break;

        default:
      }
    };
    window.addEventListener("message", handleReceiveMessage);
    return () => {
      window.removeEventListener("message", handleReceiveMessage);
    };
  }, [
    videoChooseId,
    video,
    autoCloseVideos,
    domain,
    consumerToken,
    visitToken,
  ]);

  useEffect(() => {
    if (domain !== undefined) {
      if (
        video &&
        videoRef.current &&
        visitToken &&
        videoChooseId &&
        consumerToken
      ) {
        if (!vcReadyPostMessage) {
          window.parent.postMessage(
            {
              action: "vcReady",
              consumerToken: consumerToken,
              visitToken: visitToken,
              videoChooseId: videoChooseId,
              viewedAt: getJSTDate(),
              measurementTag: "via-visit-only",
              domain: domain,
            },
            "*"
          );
        }
        setVcReadyPostMessage(true);
      }
    }
  }, [video, visitToken, videoChooseId, domain, consumerToken]);

  //再生ボタンを押した後にfadeout
  const fadeOutButton = () => {
    let opacity = 1;
    const fadeOutInterval = setInterval(() => {
      if (opacity <= 0) {
        clearInterval(fadeOutInterval);
      } else {
        opacity -= 0.15;
        setPlayButtonOpacity(opacity);
      }
    }, 1);
  };

  useEffect(() => {
    if (domain !== undefined) {
      if (video && videoRef.current) {
        if (firstAutoPlay) {
          videoRef.current.muted = true;
          videoRef.current.play();
          setProgressTransition("0.3s linear");
          fadeOutButton();
          if (autoCloseVideos.includes(video.id)) {
            handleScrollToBottom();
          }
          setTimeout(() => {
            setFirstAutoPlay(false);
          }, 7000);
          setAudioPulseClass("video-choose-audio-pulse-button");
          setIsMuted(true);
          setTimeout(() => {
            setAudioPulseClass("");
          }, 7000);

          if (
            isFirstAutoPlay &&
            process.env.REACT_APP_PROD &&
            videoChooseId &&
            video?.id
          ) {
            window.parent.postMessage(
              {
                action: "setVcCvCookie",
                consumerToken: consumerToken,
                visitToken: visitToken,
                videoChooseId: videoChooseId,
                viewedAt: getJSTDate(),
                measurementTag: "via-auto-video-only",
                domain: domain,
              },
              "*"
            );
          }
        }
      }
    }
  }, [video, firstAutoPlay, domain]);

  //Clipboardにリンクをコピーする
  const copyLinkToClipboard = async () => {
    const currentPageUrl = `${window.location.origin}${window.location.pathname}`;

    try {
      await navigator.clipboard.writeText(currentPageUrl);
      enqueueSnackbar("リンクをコピーしました", {
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } catch (err) {
      console.error("Failed to copy: ", err);
      enqueueSnackbar("コピーに失敗しました", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const handleFormModalClose = async () => {
    setFormModal(false);
    setSelectedFormId("");
  };

  const handleVideoTypeNextAction = (videoId: any) => {
    if (videoId !== undefined) {
      const nextVideo = videoChoose.videos[videoId];
      if (nextVideo) {
        setProgressTransition("none");
        setAutoPlay(true);
        setProgress(0);
        setVideoLoading(true);
        setOpacity(1);
        setVideo(nextVideo);
        setIsVideoEnd(false);
        setPlayButtonOpacity(0);

        if (videoId !== undefined) {
          setHistory((prevHistory) => {
            if (!prevHistory.includes(videoId)) {
              return [...prevHistory, videoId];
            } else {
              return prevHistory;
            }
          });
        }
      }
    }
  };

  if (loading) {
    return (
      <div className="content-gradient" style={commonStyles.gradientContainer}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress
            size={32}
            style={{ color: "rgba(255,255,255, 0.8)" }}
          />
        </Box>
      </div>
    );
  }

  return (
    <div className="content-gradient" style={commonStyles.gradientContainer}>
      {/*Progressバー */}
      <Box
        sx={[
          commonStyles.progressBarBox,
          {
            width: isClose ? "calc(97% - 45px)" : "97%",
            left: isClose ? (mdUp ? "1.5%" : "10px") : "50%",
            transform: isClose ? "none" : "translateX(-50%)",
          },
        ]}
      >
        {history.length > 0 &&
          video &&
          history.map((his, index) => {
            return (
              <LinearProgress
                key={his}
                variant="determinate"
                value={
                  his == video?.id
                    ? progress
                    : index >= history.indexOf(video.id)
                    ? 0
                    : 100
                }
                style={{
                  height: 2,
                  backgroundColor: "grey",
                  width: `calc((100% / ${history.length}) - 4px)`,
                }}
                sx={{
                  "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: "white",
                    transition: progressTransition,
                  },
                }}
              />
            );
          })}
      </Box>

      {/* iframeで読み込まれている場合は閉じるボタン */}
      {isClose && (
        <Box
          sx={{
            position: "absolute",
            top: 2,
            right: 4,
            display: "flex",
            flexDirection: "row",
            zIndex: 550,
            width: 40,
            height: 40,
            minWidth: 40,
            minHeight: 40,
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            borderRadius: "50%",
            transform: "none",
            filter: "drop-shadow(0px 0px 1px #999)",
          }}
        >
          <Button
            disableRipple
            sx={{
              width: "100%",
              padding: 0,
              transition: "none",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => {
              window.parent.postMessage(
                {
                  action: "closeVideoChoose",
                },
                "*"
              );
            }}
          >
            <Iconify
              icon={"mingcute:close-fill"}
              width={22}
              height={22}
              color={"rgba(255,255,255, 1)"}
              sx={{ pointerEvents: "none" }}
            />
          </Button>
        </Box>
      )}

      <Box sx={[commonStyles.titleBox, { width: mdUp ? "97%" : "95%" }]}>
        <Typography
          variant="caption"
          sx={[
            commonStyles.titleTypography,
            { color: video?.title_color ? video?.title_color : "#fff" },
          ]}
        >
          {video?.video_title}
        </Typography>
      </Box>

      {/*バックボタンとツールバー */}
      <Box>
        {/*左のBackボタン*/}
        {history.length > 1 && history[0] != video?.id && (
          <Box>
            <Button
              onClick={() => {
                setProgressTransition("none");
                setAutoPlay(true);
                if (video) {
                  const currentVideoIndex = history.indexOf(video.id);
                  const prevVideoId = history[currentVideoIndex - 1];
                  if (prevVideoId !== undefined) {
                    const nextVideo = videoChoose.videos[prevVideoId];
                    if (nextVideo) {
                      setProgress(0);
                      setOpacity(1);
                      setIsVideoEnd(true);
                      setVideo(nextVideo);
                      setIsVideoEnd(false);
                      if (prevVideoId !== undefined) {
                        const videoId: string = prevVideoId;
                        setHistory((prevHistory) => {
                          if (!prevHistory.includes(videoId)) {
                            return [...prevHistory, videoId];
                          } else {
                            return prevHistory;
                          }
                        });
                      }
                    }
                  }
                }
                setPlayButtonOpacity(0);
              }}
              sx={[
                commonStyles.backButton,
                {
                  left: mdUp ? 25 : iframeXSdown ? 0 : 0,
                  top: mdUp ? 75 : iframeXSdown ? 60 : 60,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  filter: "drop-shadow(0px 0px 1px #666)",
                },
              ]}
            >
              <Iconify
                icon={"ep:arrow-left"}
                width={17}
                style={{ position: "relative", top: 0 }}
                color={
                  video?.back_button_color ? video?.back_button_color : "#fff"
                }
              />
              <span
                style={{
                  color: video?.back_button_color
                    ? video?.back_button_color
                    : "#fff",
                  fontSize: 12,
                  fontWeight: 100,
                }}
              >
                戻る
              </span>
            </Button>
          </Box>
        )}

        {/* 中央の注釈スペース*/}

        <Box
          sx={[
            commonStyles.backButton,
            {
              position: "absolute",
              zIndex: 500,
              padding: 0,
              top: mdUp ? 65 : iframeXSdown ? 40 : 40,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              filter: "drop-shadow(0px 0px 1px #999)",
              left: "50%",
              transform: "translateX(-50%)",
              width: "70%",
            },
          ]}
        >
          <Annotation
            annotationUrl={video?.annotation_url || null}
            currentTime={videoRef.current?.currentTime || 0}
            annotationFontSize={video?.annotation_font_size || 6.5}
          />
        </Box>

        {/*右のツールボタン*/}
        <Box>
          <Button
            onClick={() => {
              setIsMuted((prev) => {
                if (prev) {
                  setAudioPulseClass("");
                }
                return !prev;
              });
              if (firstAutoPlay) {
                setFirstAutoPlay(false);
              }
            }}
            className={audioPulseClass}
            style={{
              position: "absolute",
              top: mdUp ? 75 : iframeXSdown ? 50 : 55,
              right: mdUp ? 25 : 10,
              zIndex: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
              borderRadius: "50%",
              width: iframeXSdown ? 30 : 40,
              height: iframeXSdown ? 30 : 40,
              minWidth: iframeXSdown ? 30 : 40,
              minHeight: iframeXSdown ? 30 : 40,
              backgroundColor: "rgba(0,0,0, 0.7)",
              transform: "none",
            }}
          >
            <Iconify
              icon={
                isMuted
                  ? "fluent:speaker-mute-24-regular"
                  : "fluent:speaker-2-16-regular"
              }
              width={iframeXSdown ? 18 : 28}
              color={"rgba(255,255,255, 1)"}
            />
          </Button>

          {/*共有リンクボタン */}
          {shareLink && (
            <Button
              onClick={copyLinkToClipboard}
              style={{
                position: "absolute",
                top: mdUp ? 130 : iframeXSdown ? 90 : 105,
                right: mdUp ? 25 : 10,
                zIndex: 500,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
                borderRadius: "50%",
                width: iframeXSdown ? 30 : 40,
                height: iframeXSdown ? 30 : 40,
                minWidth: iframeXSdown ? 30 : 40,
                minHeight: iframeXSdown ? 30 : 40,
                backgroundColor: "rgba(0,0,0, 0.7)",
                transform: "none",
              }}
            >
              <Iconify
                icon={"material-symbols:share"}
                width={iframeXSdown ? 16 : 24}
                color={"rgba(255,255,255, 1)"}
                sx={{ position: "relative", right: 1 }}
              />
            </Button>
          )}
        </Box>
      </Box>

      {/*中央のボタン */}

      <Box
        sx={[
          commonStyles.centerButton,
          {
            opacity: playButtonOpacity,
          },
        ]}
      >
        <Button
          aria-label="Play"
          style={{}}
          className={playButtonClass}
          onClick={() => {
            if (!videoRef.current?.paused) {
              videoRef.current?.pause();
              setPlayButtonOpacity(1);
              handleScrollToTop();
            } else {
              setAutoPlay(true);
              videoRef.current?.play();

              if (video && autoCloseVideos.includes(video.id)) {
                handleScrollToBottom();
              }
              fadeOutButton();
              setPlayButtonClass("");
            }
          }}
        >
          <Iconify
            icon={"mdi:play"}
            width={50}
            color={"rgba(255,255,255, 1)"}
          />
        </Button>
      </Box>

      {/*巻き戻し */}
      {isVideoEnd && (
        <Box sx={commonStyles.centerButton}>
          <Button
            aria-label="RePlay"
            style={{}}
            onClick={() => {
              videoRef.current?.play();
              if (video && autoCloseVideos.includes(video.id)) {
                handleScrollToBottom();
              }
              setProgress(0);
              setIsVideoEnd(false);
            }}
          >
            <Iconify
              icon={"mdi:replay"}
              width={50}
              color={"rgba(255,255,255, 1)"}
            />
          </Button>
        </Box>
      )}

      {videoLoading && (
        <Box sx={commonStyles.videoLoading}>
          <CircularProgress size={32} style={{ color: "rgba(0, 0, 0, 0.4)" }} />
        </Box>
      )}

      {/*動画 */}
      <Box
        onClick={() => {
          if (videoRef.current) {
            if (!videoRef.current.paused) {
              videoRef.current.pause();
              setPlayButtonOpacity(1);
              handleScrollToTop();
            }
          }
        }}
      >
        <video
          key={video?.video_url}
          playsInline
          ref={videoRef}
          poster={video?.thumbnail}
          style={{
            // height: "100vh",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            height: "100%",
            zIndex: 180,
            objectFit: mdUp ? "contain" : "cover",
            objectPosition: "center center",
          }}
          muted={isMuted}
        >
          <source src={video?.video_url} type="video/mp4" />
        </video>
        <img
          key={`thumbnail`}
          style={{
            // height: "100vh",
            width: "100%",
            height: "100%",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 200,
            opacity: opacity,
            objectFit: mdUp ? "contain" : "cover",
          }}
          src={video?.thumbnail}
        ></img>
        <Modal
          open={formModal}
          onClose={handleFormModalClose}
          closeAfterTransition
          disableScrollLock={true}
        >
          <Fade in={formModal} timeout={100}>
            <div
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                width: "100%",
                height: "100%",
                zIndex: 180,
                objectFit: "contain",
                objectPosition: "center center",
              }}
            >
              {confirmation ? (
                <Button
                  onClick={handleChangeConfirmation}
                  style={{
                    position: "absolute",
                    top: 10,
                    left: 10,
                    zIndex: 1201,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    padding: 0,
                    width: 40,
                    height: 40,
                    minWidth: 40,
                    minHeight: 40,
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    transform: "none",
                  }}
                >
                  <Iconify
                    icon={"icon-park-outline:left"}
                    width={24}
                    color={"rgba(255,255,255, 1)"}
                  />
                </Button>
              ) : (
                <Button
                  onClick={handleFormModalClose}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    zIndex: 1200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    padding: 0,
                    width: 40,
                    height: 40,
                    minWidth: 40,
                    minHeight: 40,
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    transform: "none",
                  }}
                >
                  <Iconify
                    icon={"mingcute:close-fill"}
                    width={24}
                    color={"rgba(255,255,255, 1)"}
                  />
                </Button>
              )}

              {videoChooseId &&
                forms[selectedFormId] &&
                forms[selectedFormId].fields &&
                selectedFormId &&
                (forms[selectedFormId].form_group !== undefined ? (
                  <VideoChooseForm
                    videoChooseId={videoChooseId}
                    formId={selectedFormId}
                    form={forms[selectedFormId]}
                    fields={forms[selectedFormId].fields}
                    videoRef={videoRef}
                    formsOrdered={
                      // @ts-ignore: Suppress TypeScript error for indexing with potentially undefined key
                      videoChoose.formGroups[forms[selectedFormId].form_group]
                        .formOrder
                    }
                    filledFormStep={filledFormStep}
                    handleFormStep={handleFormStep}
                    handleFormStepProgressBar={handleFormStepProgressBar}
                    selectedFormStep={selectedFormStep}
                    handleFormModalClose={handleFormModalClose}
                    handleVideoTypeNextAction={handleVideoTypeNextAction}
                    setSavedValues={setSavedValues}
                    savedValues={savedValues}
                    isIFrame={isIFrame}
                    confirmation={confirmation}
                    handleChangeConfirmation={handleChangeConfirmation}
                  />
                ) : (
                  <VideoChooseForm
                    videoChooseId={videoChooseId}
                    formId={selectedFormId}
                    form={forms[selectedFormId]}
                    fields={forms[selectedFormId].fields}
                    videoRef={videoRef}
                    handleFormModalClose={handleFormModalClose}
                    handleVideoTypeNextAction={handleVideoTypeNextAction}
                    setSavedValues={setSavedValues}
                    savedValues={savedValues}
                    isIFrame={isIFrame}
                    confirmation={confirmation}
                    handleChangeConfirmation={handleChangeConfirmation}
                  />
                ))}
            </div>
          </Fade>
        </Modal>
      </Box>
      <Subtitle
        subtitleUrl={video?.subtitle_url || null}
        currentTime={videoRef.current?.currentTime || 0}
        isOptions={false}
        opacity={buttonOpacity}
        subtitleTextColor={
          video?.subtitle_text_color ? video?.subtitle_text_color : "#fff"
        }
        subtitleBackgroundColor={
          video?.subtitle_background_color
            ? video?.subtitle_background_color
            : "rgba(20, 20, 20, 0.5)"
        }
        subtitleFontSize={iframeXSdown ? 12 : video?.subtitle_font_size || 13}
      />
      {/* 選択肢トグルボタン */}
      {isShowButton && (
        <Button
          onClick={handleScrollToTop}
          className={"pulse-btn"}
          sx={[
            commonStyles.toggleOptionButton,
            {
              opacity: buttonOpacity,
            },
          ]}
        >
          <Iconify
            width={16}
            icon={"flowbite:angle-top-solid"}
            sx={{ color: "#fff" }}
          />
        </Button>
      )}

      {/* 選択肢と字幕 */}
      <Box
        ref={boxRef}
        onScroll={handleScroll}
        sx={[
          commonStyles.optionsBox,
          {
            paddingTop: iframeXSdown
              ? "calc((var(--vh, 1vh) * 60) - 1.5rem)"
              : "calc((var(--vh, 1vh) * 60) - 1.5rem)",
            maxHeight: iframeXSdown ? 260 : 380,
          },
        ]}
      >
        <Subtitle
          subtitleUrl={video?.subtitle_url || null}
          currentTime={videoRef.current?.currentTime || 0}
          isOptions={true}
          opacity={buttonOpacity}
          subtitleTextColor={
            video?.subtitle_text_color ? video?.subtitle_text_color : "#fff"
          }
          subtitleBackgroundColor={
            video?.subtitle_background_color
              ? video?.subtitle_background_color
              : "rgba(20, 20, 20, 0.5)"
          }
          subtitleFontSize={iframeXSdown ? 12 : video?.subtitle_font_size || 13}
        />

        {video?.nextActions.map((nextAction) => {
          return (
            <Button
              key={nextAction}
              sx={
                videoChoose.nextActions[nextAction].button_display_type &&
                videoChoose.nextActions[nextAction].button_display_type ===
                  "html"
                  ? [{ width: "100%", padding: 0, marginBottom: "8px" }]
                  : [
                      commonStyles.optionButton,
                      {
                        backgroundColor:
                          videoChoose.nextActions[nextAction].option_color !=
                            "" &&
                          videoChoose.nextActions[nextAction].option_color !=
                            null
                            ? videoChoose.nextActions[nextAction].option_color
                            : "rgba(5, 5, 5, 0.5)",
                        "&:hover": {
                          backgroundColor:
                            videoChoose.nextActions[nextAction].option_color !=
                              "" &&
                            videoChoose.nextActions[nextAction].option_color !=
                              null
                              ? videoChoose.nextActions[nextAction].option_color
                              : "rgba(5, 5, 5, 0.5)",
                        },
                        padding: iframeXSdown ? "8px 0" : "10px 0",
                        width: iframeXSdown ? "90%" : "100%",
                      },
                    ]
              }
              onClick={() => {
                if (videoChoose.nextActions[nextAction].type == "video") {
                  const nextVideoId = videoChoose.nextActions[nextAction];
                  if (
                    nextVideoId !== undefined &&
                    nextVideoId.video_id !== undefined
                  ) {
                    const nextVideo = videoChoose.videos[nextVideoId.video_id];
                    if (nextVideo) {
                      setProgressTransition("none");
                      setAutoPlay(true);
                      setProgress(0);
                      setVideoLoading(true);
                      setOpacity(1);
                      setVideo(nextVideo);
                      setIsVideoEnd(false);
                      setPlayButtonOpacity(0);

                      if (nextVideoId.video_id !== undefined) {
                        const videoId: string = nextVideoId.video_id;
                        setHistory((prevHistory) => {
                          if (!prevHistory.includes(videoId)) {
                            return [...prevHistory, videoId];
                          } else {
                            return prevHistory;
                          }
                        });
                      }
                    }
                  }
                } else if (
                  videoChoose.nextActions[nextAction].type == "outer_link"
                ) {
                  if (videoRef.current) {
                    videoRef.current.pause();
                    setPlayButtonOpacity(1);
                  }

                  if (isIFrame) {
                    if (videoChoose.nextActions[nextAction].open_new_window) {
                      window.open(
                        videoChoose.nextActions[nextAction].outer_link,
                        "_blank"
                      );
                    } else {
                      window.open(
                        videoChoose.nextActions[nextAction].outer_link,
                        "_self"
                      );
                    }
                  } else {
                    window.open(
                      videoChoose.nextActions[nextAction].outer_link,
                      "_blank"
                    );
                  }

                  if (
                    videoChoose.nextActions[nextAction].measurement_tag !==
                      "" &&
                    videoChoose.nextActions[nextAction].measurement_tag !==
                      undefined &&
                    process.env.REACT_APP_PROD
                  ) {
                    window.parent.postMessage(
                      {
                        action: "setVcCvCookie",
                        measurementTag:
                          videoChoose.nextActions[nextAction].measurement_tag,
                        videoId: video.id,
                        domain: domain,
                      },
                      "*"
                    );
                  }
                } else if (
                  videoChoose.nextActions[nextAction].type == "internal_link"
                ) {
                  if (videoRef.current) {
                    videoRef.current.pause();
                    setPlayButtonOpacity(1);
                  }

                  if (isIFrame) {
                    sendMessage(
                      videoChoose.nextActions[nextAction].anchor,
                      videoChoose.nextActions[nextAction].is_close_anchor_click
                    );
                  } else {
                    if (videoChoose.nextActions[nextAction].other_link) {
                      window.open(
                        videoChoose.nextActions[nextAction].other_link,
                        "_blank"
                      );
                    }
                  }
                } else if (
                  videoChoose.nextActions[nextAction].type == "custom_form"
                ) {
                  if (videoRef.current) {
                    videoRef.current.pause();
                    setPlayButtonOpacity(1);
                  }

                  const nextVideoId = videoChoose.nextActions[nextAction];
                  if (
                    nextVideoId !== undefined &&
                    nextVideoId.form_id !== undefined
                  ) {
                    setFormModal(true);
                    setSelectedFormId(nextVideoId.form_id);
                    if (forms[nextVideoId.form_id].form_group !== undefined) {
                      const formStep = videoChoose.formGroups[
                        // @ts-ignore: Suppress TypeScript error for indexing with potentially undefined key
                        forms[nextVideoId.form_id].form_group
                      ].formOrder.findIndex(
                        (form: any) => form.formId === nextVideoId.form_id
                      );

                      setSelectedFormStep(formStep);
                      setFilledFormStep(formStep);
                    }
                  }

                  if (
                    videoChoose.nextActions[nextAction].measurement_tag !==
                      "" &&
                    videoChoose.nextActions[nextAction].measurement_tag !==
                      undefined &&
                    process.env.REACT_APP_PROD
                  ) {
                    window.parent.postMessage(
                      {
                        action: "setVcCvCookie",
                        measurementTag:
                          videoChoose.nextActions[nextAction].measurement_tag,
                        videoId: video.id,
                        domain: domain,
                      },
                      "*"
                    );
                  }
                } else if (
                  videoChoose?.nextActions?.[nextAction]?.type === "phone"
                ) {
                  if (videoRef.current) {
                    videoRef.current.pause();
                    setPlayButtonOpacity(1);
                  }
                  const action = videoChoose.nextActions[nextAction];

                  if (
                    action &&
                    action.phone_country_code !== undefined &&
                    action.phone_number !== undefined
                  ) {
                    const phone_number_with_country_code =
                      action.phone_country_code + action.phone_number;

                    const isPhoneSupported = (): boolean => {
                      const userAgent = navigator.userAgent;
                      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
                        userAgent
                      );
                    };

                    const makeCall = (phoneNumber: string): void => {
                      window.open(`tel:${phoneNumber}`);
                    };

                    const displayPhoneNumber = (phoneNumber: string): void => {
                      alert(
                        `このデバイスは直接電話をかけることができません。\nお手持ちの電話で以下の番号におかけください\n\n${phoneNumber}`
                      );
                    };

                    const convertToDomesticPhoneNumber = (
                      phoneNumber: string,
                      countryCode: string
                    ): string => {
                      const countryCodeMapping: {
                        [key: string]: (number: string) => string;
                      } = {
                        "+81": (number: string) => "0" + number.slice(3),
                        "+1": (number: string) => number.slice(2),
                      };

                      if (countryCodeMapping[countryCode]) {
                        return countryCodeMapping[countryCode](phoneNumber);
                      }

                      return phoneNumber; // Return the original phone number if no conversion logic is found
                    };

                    if (isPhoneSupported()) {
                      makeCall(phone_number_with_country_code);
                    } else {
                      const domesticPhoneNumber = convertToDomesticPhoneNumber(
                        phone_number_with_country_code,
                        action.phone_country_code
                      );
                      displayPhoneNumber(domesticPhoneNumber);
                    }
                  }
                } else if (
                  videoChoose?.nextActions?.[nextAction]?.type ===
                  "custom_script"
                ) {
                  if (videoRef.current) {
                    videoRef.current.pause();
                    setPlayButtonOpacity(1);
                  }

                  if (isIFrame) {
                    sendCostomScriptIdMessage(
                      videoChoose.nextActions[nextAction].custom_script_id
                    );
                  } else {
                    if (videoChoose.nextActions[nextAction].other_link) {
                      window.open(
                        videoChoose.nextActions[nextAction].other_link,
                        "_blank"
                      );
                    }
                  }

                  if (
                    videoChoose.nextActions[nextAction].measurement_tag !==
                      "" &&
                    videoChoose.nextActions[nextAction].measurement_tag !==
                      undefined &&
                    process.env.REACT_APP_PROD
                  ) {
                    window.parent.postMessage(
                      {
                        action: "setVcCvCookie",
                        measurementTag:
                          videoChoose.nextActions[nextAction].measurement_tag,
                        videoId: video.id,
                        domain: domain,
                      },
                      "*"
                    );
                  }
                }
              }}
            >
              {videoChoose.nextActions[nextAction].button_display_type &&
              videoChoose.nextActions[nextAction].button_display_type ===
                "html" &&
              videoChoose.nextActions[nextAction].button_html !== undefined ? (
                <div
                  style={{ width: "100%", textTransform: "none" }}
                  dangerouslySetInnerHTML={{
                    __html: videoChoose.nextActions[nextAction].button_html!,
                  }}
                />
              ) : (
                <span
                  style={{
                    color: videoChoose.nextActions[nextAction].option_text_color
                      ? videoChoose.nextActions[nextAction].option_text_color
                      : "#fff",
                    textTransform: "none",
                    fontSize: iframeXSdown ? 14 : 16,
                    fontWeight: 400,
                  }}
                >
                  {videoChoose.nextActions[nextAction].option_text}
                </span>
              )}

              {/* <Iconify
                style={{ position: "absolute", right: 12 }}
                icon={"mingcute:right-line"}
                width={22}
                color={"#ddd"}
              /> */}
            </Button>
          );
        })}

        {hideCopyright ? (
          <Box
            sx={[
              commonStyles.footerBox,
              { padding: iframeXSdown ? "0.2rem 0" : "0.6rem 0" },
            ]}
          ></Box>
        ) : (
          <Box
            sx={[
              commonStyles.footerBox,
              { padding: iframeXSdown ? "0.2rem 0" : "0.6rem 0" },
            ]}
          >
            <Box
              style={{
                fontSize: 12,
                fontWeight: 100,
                letterSpacing: "-0.2px",
              }}
            >
              Powered by &nbsp;
            </Box>
            <Button
              onClick={() => {
                if (videoRef.current) {
                  videoRef.current.pause();
                  setPlayButtonOpacity(1);
                }
                window.open(`${process.env.REACT_APP_CONSUMER_URL}`, "_blank");
              }}
              sx={{
                height: "1px",
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
            >
              <img
                src={"/assets/footer-logo.png"}
                style={{ width: 110, borderRadius: 1.5 }}
              />
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
}

const commonStyles = {
  gradientContainer: {
    background: "rgba(0,0,0,.75)",
    display: "flex",
    justifyContent: "center",
    // height: "100vh",
    height: "-webkit-fill-available",
    position: "fixed" as "fixed",
    top: 0,
    width: "100%",
    zIndex: 300,
    right: 0,
    bottom: 0,
    left: 0,
  },
  progressBarBox: {
    position: "absolute",
    top: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 500,
  },

  titleBox: {
    position: "absolute",
    zIndex: 500,
    top: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  titleTypography: {
    fontWeight: 100,
    fontSize: 10,
    textAlign: "left",
    filter: "drop-shadow(0px 0px 1px #666)",
  },
  backButton: {
    position: "absolute",
    zIndex: 500,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 0,
  },

  speakerButtonBox: {
    position: "absolute",
    top: "calc(37% - 2.5rem)",
    zIndex: 500,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  speakerButton: {
    borderRadius: 54,
    width: 80,
    height: 80,
    backgroundColor: "rgba(0,0,0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    transform: "none",
  },

  centerButton: {
    position: "absolute",
    top: "calc(37% - 2.5rem)",
    zIndex: 500,
    borderRadius: 54,
    width: 80,
    height: 80,
    backgroundColor: "rgba(0,0,0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "none",
  },

  videoLoading: {
    position: "absolute",
    top: "calc(37% - 2.5rem)",
    zIndex: 500,
    borderRadius: 54,
    width: 80,
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "none",
  },

  toggleOptionButton: {
    position: "absolute",
    transition: "bottom 0.5s ease-in-out",
    bottom: 30,
    zIndex: 500,
    minWidth: "auto",
    borderRadius: "50%",
  },

  optionsBox: {
    position: "absolute",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: 570,
    transition: "transform 1s ease-in-out",
    paddingLeft: "124px",
    paddingRight: "124px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    zIndex: 300,
    maskImage: "linear-gradient(to bottom, #0000, #000 2rem, #000)",
  },
  optionButton: {
    position: "relative",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .15s 0s cubic-bezier(.25,.46,.45,.94)",
    willChange: "all",
    borderRadius: "2rem",
    height: "3.2rem",
    // padding: "1rem 0",
    fontWeight: 500,
    color: "#fff",
    textDecoration: "none",
    display: "inline-flex",
    backgroundColor: "rgba(5, 5, 5, 0.5)",
    marginBottom: "8px",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "rgba(5, 5, 5, 0.5)",
    },
  },

  footerBox: {
    position: "relative",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .15s 0s cubic-bezier(.25,.46,.45,.94)",
    borderRadius: "2rem",
    height: "2.6rem",
    fontWeight: 500,
    color: "#fff",
    textDecoration: "none",
    display: "inline-flex",
    width: "100%",
    marginTop: "6px",
    marginBottom: "2px",
    opacity: 0.7,
  },
};
